/*    
<summary>
   This functional common components provides us custom buttons for grid. 
   Developer:Mohammad Saquib Khan, Created Date:31-March-2024
</summary> 
<returns>Returns JSX</returns>
*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Grid.css";
import { formatMessage } from "../../translations/format-message";
import { IActionFormatter } from "../../models/ICommon";
import config from "../../helpers/config-helper";
import { parkingLotStatus } from "../../constants/enums/parking-lot-status-enum";

const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT;

export const ActionFormatter =
  (actions: Array<IActionFormatter>) => (cell: any, row: any) => {
    return (
      <div className="d-flex">
        {actions.map((action: IActionFormatter, index: number) => {
          return (
            <button
              className={`btn btn-light-${action.varient} `}
              key={index}
              title={formatMessage(action.title)}
              type="button"
              disabled={action.isDisable && !row[action.dataField]}
              onClick={(event) => action.handler(row, event)}
            >
              <FontAwesomeIcon icon={action.icon} />
            </button>
          );
        })}
      </div>
    );
  };

export const CustomActionFormatter =
  (actions: Array<IActionFormatter>) => (cell: any, row: any) => {
    return (
      <div className="d-flex">
        {actions.map((action: IActionFormatter, index: number) => {
          return (
            <button
              className={`btn btn-light-${action.varient} btn-sm`}
              key={index}
              title={formatMessage(action.title)}
              type="button"
              disabled={action.isDisable && !row[action.dataField]}
              onClick={(event) => action.handler(row, event)}
            >
              {action.icon}
            </button>
          );
        })}
      </div>
    );
  };

export const CustomParkingLotActionFormatter =
  (actions: Array<IActionFormatter>) => (cell: any, row: any) => {
    return (
      <div className="d-flex">
        {actions.map((action: IActionFormatter, index: number) => {
          if(action.hidden == undefined || action.hidden == false){
            return (
              <button
                className={`btn btn-light-${action.varient} btn-sm`}
                key={index}
                title={action.dataField == "isParkingLotOpenClosed" ? row.CurrentStatus === parkingLotStatus.Available ? formatMessage(action.title1) : formatMessage(action.title2) : formatMessage(action.title)}
                type="button"
                disabled={action.isDisable && !row[action.dataField]}
                onClick={(event) => action.handler(row, event)}
              >
                {action.dataField == "isParkingLotOpenClosed"
                  ? (row.CurrentStatus === parkingLotStatus.Available || row.CurrentStatus === parkingLotStatus.Crowded || row.CurrentStatus === parkingLotStatus.Full)
                    ? action.icon2
                    : action.icon1
                  : action.icon1}
              </button>
            );
          }
          else{
            return "";
          }
        })}
      </div>
    );
  };

  export const CustomIntegratorActionFormatter =
  (actions: Array<IActionFormatter>) => (cell: any, row: any) => {
    return (
      <div className="d-flex">
        {actions.map((action: IActionFormatter, index: number) => {
          if(action.hidden == undefined || action.hidden == false){
            return (
              <button
                className={`btn btn-light-${action.varient} btn-sm`}
                key={index}
                title={action.dataField == "isIntegratorOpenClosed" ? row.CurrentStatus === parkingLotStatus.Available ? formatMessage(action.title1) : formatMessage(action.title2) : formatMessage(action.title)}
                type="button"
                disabled={action.isDisable && !row[action.dataField]}
                onClick={(event) => action.handler(row, event)}
              >
                {action.dataField == "isIntegratorOpenClosed"
                  ? (row.CurrentStatus === parkingLotStatus.Available || row.CurrentStatus === parkingLotStatus.Crowded || row.CurrentStatus === parkingLotStatus.Full)
                    ? action.icon2
                    : action.icon1
                  : action.icon1}
              </button>
            );
          }
          else{
            return "";
          }
        })}
      </div>
    );
  };
