/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined constant.
   This constant is used in unit data modules to provide default unit data.
   Developer: Aashish Singh, Created Date:29-March-2023
</summary>
*/

import moment from "moment";
import scheduleTypeEnum from "./enums/schedule-type-enum";
// import CryptoJS from 'crypto-js';
import toast from "react-hot-toast";

export const emptyValue = "-";
export const defautValueToBeChanged = -999;
export const superAdminUserName = "admin@test.com";

export const getDaysOptions = (noOfDays: number) => {
  let optionsArray = [];
  for (let i = 1; i <= noOfDays; i++) {
    optionsArray.push({ key: i, value: i });
  }
  return optionsArray;
};

export const clearLocalStorageFields = () => {
  const lang: string | null = localStorage.getItem("hips_language");
  const pageLimitConfig: string | null = localStorage.getItem(
    "hips_pageLimitConfig"
  );
  const tagPageLimitConfig: string | null = localStorage.getItem(
    "hips_tagPageLimitConfig"
  );
  const selectedDays: string | null = localStorage.getItem(
    "hips_selectedDays"
  );
  localStorage.clear();
  localStorage.setItem("hips_language", lang === "jp" ? "jp" : "en");
  if (pageLimitConfig)
    localStorage.setItem("hips_pageLimitConfig", pageLimitConfig);
  if (tagPageLimitConfig)
    localStorage.setItem("hips_tagPageLimitConfig", tagPageLimitConfig);
  if (selectedDays) localStorage.setItem("hips_selectedDays", selectedDays);
  localStorage.setItem("hips_language", lang === "jp" ? "jp" : "en");
};

// Function to combine date and time into a single Date object
const combineDateTime = (date: string, time: string): moment.Moment => {
  return moment(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss");
};

export const isDateTimeOverlap = (
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
  existingData: any[],
  ssId: number
): boolean => {
  
  const newStart = combineDateTime(startDate, startTime);
  const newEnd = combineDateTime(endDate, endTime);

  for (const data of existingData) {
    const existingStart = combineDateTime(data.StartDate, data.StartTime);
    const existingEnd = combineDateTime(data.EndDate, data.EndTime);

    // Check if there is an overlap
    if (newStart.isBefore(existingEnd) && newEnd.isAfter(existingStart) && ssId != data.SsId) 
    {
      return true; // Overlap found
    }
  }

  return false; // No overlap
};

export const isSpecialDateTimeOverlap = (
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
  existingData: any[],
  ssId: number,
  scheduleType: number
): boolean => {
  const newStart = combineDateTime(startDate, startTime);
  const newEnd = combineDateTime(endDate, endTime);

  for (const data of existingData) {
    const existingStart = combineDateTime(data.StartDate, data.StartTime);
    const existingEnd = combineDateTime(data.EndDate, data.EndTime);

    // Check if there is an overlap
    if(ssId !== data.SsId){
      if(data.ScheduleType == scheduleType){
        if (
          (newStart.isBefore(existingEnd) && newEnd.isAfter(existingStart)) || // Overlaps with existing range
          (newStart.isBefore(existingStart) && newStart.isSameOrAfter(existingStart)) || // New period starts before and overlaps with the existing start
          (newStart.isBefore(existingStart) && newEnd.isAfter(existingEnd)) // New period ends after existing period
        ) {
          if(ssId == data.SsId){
            return false;
          }
          else{
            return true; // Overlap found
          }
        }
      }
    }
  }

  return false; // No overlap
};

// Validation function to check if StartDateTime is greater than EndDateTime
export const isStartDateTimeGreaterThanEndDateTime = (
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string
): boolean => {
  const start = combineDateTime(startDate, startTime);
  const end = combineDateTime(endDate, endTime);

  return start.isSameOrAfter(end);
};

export const isPresent = (
  scheduleArray: any,
  time: any,
  weekday: any,
  psId: number
) => {
   
  return scheduleArray.some(
    (schedule: any) => {
      return (schedule.StartTime === time && schedule.Weekday === weekday && psId != schedule.PsId);
    }
  );
};

export const getComponentName = (name: string) => {
  return name.replace(/^\//, "");
};

export const bytesToMegabytes = (bytes:number):number => {
  if (isNaN(bytes) || bytes < 0) {
    return 0;
  }
  return (bytes / (1024 * 1024)); // Convert bytes to MB and fix to 2 decimal places
};


// export const encryptData = (data: string, secretKey:string): string => {
//   return CryptoJS.AES.encrypt(data, secretKey).toString();
// };

// // Decrypt data
// export const decryptData = (ciphertext: string, secretKey: string): string => {
//   const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
//   if(bytes.toString(CryptoJS.enc.Utf8) == null || bytes.toString(CryptoJS.enc.Utf8) == ""){
//     // toast.error('LocalStorage Mishandled, Logg out now!')
//   }
//   return bytes.toString(CryptoJS.enc.Utf8);
// };