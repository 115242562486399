/*    
<summary>
   This class component is all about Managing user data functionality.
   Developer:Mohammad Saquib Khan, Created Date:29-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import { IObservableInitialState } from "../../models/ICommon";
import { AuthStore } from "./auth-store";
import { ICommonState } from "../../models/state/ICommonState";
import { IUserState } from "../../models/state/IUserState";
import URLConstants from "../../constants/url-constants";
import * as baseService from "../service/base-service";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import userTypeEnum from "../../constants/enums/user-type-enum";
import {
  IHucksterUserList,
  IHucksterUserListVM,
  IHucksterUserVM,
} from "../../models/response/IUserResponse";
import { initialState } from "../initialState/add-edit-user-state";
import base64 from "base-64";
import { IAddHucksterUser, IAddUser, ISendMail } from "../../models/forms/IAddUser";
import { toast } from "react-hot-toast";
import { formatMessage } from "../../translations/format-message";
import { initialState as GetAllUserState } from "../initialState/get-all-user-state";
import { PermissionType } from "../../constants/enums/permission-type-enum";
import accountStatusEnum from "../../constants/enums/account-status-type-enum";
import { getIsTenantAccessFromLocalStorage, getUserIdFromToken } from "../../helpers/localstorage-helper";

export class UserStore implements IUserState, ICommonState {
  inProgress = false;
  error = "";
  tenantAccessId = getUserIdFromToken();
  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  userList: IHucksterUserListVM = GetAllUserState;

  user: any | undefined = undefined;
  userState = { ...this.initialStateValue };

  activateUserAccountState = { ...this.initialStateValue };

  addUpdateUserState = { ...this.initialStateValue };
  deleteUserState = { ...this.initialStateValue };

  resendActivationLinkState = { ...this.initialStateValue };

  constructor() {
    makeObservable(this, {
      user: observable,
      userState: observable,
      userList: observable,
      addUpdateUserState: observable,
      deleteUserState: observable,
      activateUserAccountState: observable,
      resendActivationLinkState: observable,
      GetHucksterUserListService: action,
      GetSystemUserListService: action,
      AddHucksterUserService: action,
      ActivateUserService: action,
      UpdateUserService: action,
      GetUserByIdService: action,
      ResendAccountActivateLinkService: action,
      resetAddUpdateUserState: action,
      resetAccountActivationLink: action,
      reset: action,
      resetUserDetail: action,
      resetDeleteUser: action,
      resetAccountActivationState: action,
      allHucksterUser: computed,
      userDetail: computed,
      allSystemUser: computed,
      systemUserDetail: computed,
    });
  }

  /**
   * This function is used to Get All Users List data with pagination information by calling an API.
   * @param currentPage : Current Page Number
   * @param pagerSize : Page Size
   * @returns
   */
  GetHucksterUserListService = (currentPage: number, pagerSize: number) => {
    this.inProgress = true;
    const url =
      URLConstants.GetHucksterUserList +
      "?PageNo=" +
      currentPage +
      "&PageSize=" +
      pagerSize;
    return baseService
      .getRequest(url)
      .then(
        (response: IApiResponse<IApiSuccessResponse<IHucksterUserListVM>>) => {
          if (response.data.Error) {
            this.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.userList = response.data.Data;
          }
        }
      )
      .catch((err: string) => {
        this.error = err;
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  GetSystemUserListService = (
    currentPage: number,
    pagerSize: number,
    tenantId: number
  ) => {
    this.inProgress = true;
    const url =
      URLConstants.GetSystemUserList +
      "?PageNo=" +
      currentPage +
      "&PageSize=" +
      pagerSize +
      "&tenantid=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then(
        (response: IApiResponse<IApiSuccessResponse<IHucksterUserListVM>>) => {
          if (response.data.Error) {
            this.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.userList = response.data.Data;
          }
        }
      )
      .catch((err: string) => {
        this.error = err;
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  /**
   * This function is used to map userList to allUser List suitable for Grid Component.
   * @returns allUsersList
   */
  get allHucksterUser(): IHucksterUserList[] {
    let userData: IHucksterUserList[] = [];
    if (this.userList?.Users && this.userList.Users?.length > 0)
      this.userList.Users.map((user) => {
        let isDelete = true;
        let isEdit = true;
        let isChangePassword = false;
        let isActivationLink = false;
        let isResend = true;
        if (user?.Tenant?.TenantId == -1) {
          userData.push({
            Id: user.Id,
            Tenant: user.Tenant,
            Email: user.Email,
            UserName: user.UserName,
            UserType: userTypeEnum[user.UserType],
            AccountStatus: user.AccountStatus,
            isResend: (user.Id == this.tenantAccessId ? false : (user.AccountStatus == "Active") ? false : true),
            isEdit: (user.Id == this.tenantAccessId ? false : (user.AccountStatus == "Active") ? true : false),
            isDelete: user.Id == this.tenantAccessId ? false : isDelete,
            isChangePassword: isChangePassword,
            isActivationLink: isActivationLink,
          });
        }
      });
    return userData;
  }

  /**
   * This function is used to map userList to allUser List suitable for Grid Component.
   * @returns allUsersList
   */
  get allSystemUser(): IHucksterUserList[] {
    let userData: IHucksterUserList[] = [];
    if (this.userList?.Users && this.userList.Users?.length > 0)
      this.userList.Users.map((user) => {
        let isDelete = true;
        let isEdit = true;
        let isChangePassword = false;
        let isActivationLink = false;
        let isResend = true;
        if (user?.Tenant.TenantId != -1) {
          userData.push({
            Id: user.Id,
            Tenant: user.Tenant,
            Email: user.Email,
            UserName: user.UserName,
            UserType: userTypeEnum[user.UserType],
            AccountStatus: user.AccountStatus,
            isResend: (user.Id == this.tenantAccessId ? false : (user.AccountStatus == "Active") ? false : true),
            isEdit: (user.Id == this.tenantAccessId ? false : (user.AccountStatus == "Active") ? true : false),
            isDelete: user.Id == this.tenantAccessId ? false : isDelete,
            isChangePassword: isChangePassword,
            isActivationLink: isActivationLink,
          });
        }
      });
    return userData;
  }

  /**
   * This function is used to Resend User Activation Link by calling an API.
   * @param data : User Data
   * @returns
   */
  ResendAccountActivateLinkService = (data: ISendMail) => {
    this.resendActivationLinkState.inProgress = true;
    let url = URLConstants.ResendLink;
    return baseService
      .postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.resendActivationLinkState.error = response.data.Message;
        } else this.resendActivationLinkState.success = true;
      })
      .catch((err: string) => {
        this.resendActivationLinkState.error = err;
      })
      .finally(
        action(() => {
          this.resendActivationLinkState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Add New User by calling an API.
   * @param data : User Data
   * @returns
   */
  AddHucksterUserService = (data: IAddHucksterUser) => {
    this.addUpdateUserState.inProgress = true;
    let url = URLConstants.AddHucksterUser;
    return baseService
      .postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateUserState.error = response.data.Message;
        } else this.addUpdateUserState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateUserState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateUserState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Updating Existing User Details by calling an API.
   * @param id : The User Identifier
   * @param data
   * @returns
   */
  UpdateUserService = (id: number, data: IAddHucksterUser) => {
    this.addUpdateUserState.inProgress = true;
    let url = URLConstants.UpdateUser;
    return baseService
      .putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateUserState.error = response.data.Message;
        } else this.addUpdateUserState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateUserState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateUserState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Activate User Account by calling an API.
   * @param id : The User Identifier
   * @param data
   * @returns
   */
  ActivateUserService = (
    userId: number,
    email: string,
    accountStatus: number
  ) => {
    this.activateUserAccountState.inProgress = true;
    let url = URLConstants.ActivateAccount;
    let obj = {
      UserId: userId,
      Email: email,
      AccountStatus: accountStatus,
    };
    return baseService
      .postRequest(url, obj)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.activateUserAccountState.error = response.data.Message;
        } else {
          this.activateUserAccountState.success = true;
          this.activateUserAccountState.error = response.data.Message;
        }
      })
      .catch((err: string) => {
        this.activateUserAccountState.error = err;
      })
      .finally(
        action(() => {
          this.activateUserAccountState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Delete User by calling an API.
   * @param id : The User Identifier
   * @returns
   */
  DeleteUserService = (id: number) => {
    this.deleteUserState.inProgress = true;
    let url = URLConstants.DeleteUser + "/" + id;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteUserState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deleteUserState.success = true;
      })
      .catch((err: string) => {
        this.deleteUserState.error = err;
      })
      .finally(
        action(() => {
          this.deleteUserState.inProgress = false;
        })
      );
  };

  /*
    This function is used to reset all AddUser observables to their initial values.  
    */
  resetAddUpdateUserState = () => {
    this.addUpdateUserState.inProgress = false;
    this.addUpdateUserState.success = false;
    this.addUpdateUserState.error = "";
  };

  /**
   * This function is used to Get User Details by Id by calling an API.
   * @param id : The User Identifier
   * @returns
   */
  public GetUserByIdService = (id: number) => {
    this.userState.inProgress = true;
    let url = `${URLConstants.GetUserById}/${id}`;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IHucksterUserVM>>) => {
        if (response.data.Error) {
          this.userState.error = response.data.Message;
        } else {
          let data = response.data.Data;
          this.user = data;
        }
      })
      .catch((err: string) => {
        this.userState.error = err;
      })
      .finally(
        action(() => {
          this.userState.inProgress = false;
        })
      );
  };

  /**
   * This function provides initail values to the Add User Form.
   * @returns User Details
   */
  get userDetail() {
    if (this.user) {
      return {
        UserType: this.user.UserType,
        Email: this.user.Email,
        IsActive: this.user.IsActive,
        UserName: this.user.UserName,
      };
    }
    return initialState;
  }

  /**
   * This function provides initail values to the Add User Form.
   * @returns User Details
   */
  get systemUserDetail(): any {
    if (this.user) {
      return {
        TenantId: this.user?.Tenant.TenantId,
        UserType: this.user.UserType,
        Email: this.user.Email,
        IsActive: this.user.IsActive,
        UserName: this.user.UserName,
      };
    }
    return initialState;
  }

  /**
    This function is used to reset all user observables to their initial values.  
    */
  resetAccountActivationLink = () => {
    this.resendActivationLinkState = {...this.initialStateValue};
  }

  resetUserDetail = () => {
    this.user = undefined;
    this.userState = { ...this.initialStateValue };
  };

  resetDeleteUser = () => {
    this.deleteUserState = { ...this.initialStateValue };
  };

  resetAccountActivationState = () => {
    this.activateUserAccountState = { ...this.initialStateValue };
  };

  /*
    This function is used to reset all observables to their initial values.  
    */
  reset = () => {
    this.error = "";
    this.inProgress = false;
    this.addUpdateUserState.success = false;
    this.addUpdateUserState.error = "";
    this.deleteUserState = { ...this.initialStateValue };
    this.addUpdateUserState.inProgress = false;
    this.userState = { ...this.initialStateValue };
  };
}

export default new UserStore();
