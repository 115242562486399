/*    
<summary>
   This file is used to provide initial values or state to the User Data observables.
   Developer:Mohammad Saquib Khan, Created Date: 02-April-2024
</summary>
*/

import { IAddHucksterUser, IAddUser } from "../../models/forms/IAddUser";


export const initialState:IAddHucksterUser = {
    UserType: -1,
    Password: '', 
    Email: '',
    UserName: '',
    AccountStatus: 0,
    TenantId: -1
}