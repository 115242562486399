/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined constant.
   This constant is used in displaying error messages according to the error code recieved from the backend API.
      Developer: Aashish Singh, Created Date: 29-March-2023
</summary>
*/
export const Error = { 
    InvalidUsernameOrPassword: "invalid_username_or_password",
}
export const errorMessage = {
    ErrorOccured: 'message_some_error_occured',
    Unauthorized: "unauthorized",
    UnauthorizedAccess: "unauthorized_access",
    UserAlreadyLogin: "UserAlreadyLogin",
    AccessedByOtherUser:"AccessedByOtherUser",
    SessionExpired:"SessionExpired",
    InvalidEmailOrPassword:"InvalidEmailOrPassword",
    UserEmailNotFound:"UserEmailNotFound",
    YourAccountInactive:"YourAccountInactive",
}
