/*    
<summary>
  This functional component "InactivityPopup" provides popup 
  Developer:Aashish Singh, Created Date:10-July-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useStore } from '../../../contexts/store-provider';
import RoutesConstants from '../../routes/routes-constants';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { formatMessage } from '../../../translations/format-message';
import { observer } from 'mobx-react';

const InactivityPopup: React.FC = () => {
  const { authStore, userStore, tenantStore } = useStore();
  const { logout, logoutError, logoutSuccess, resetLogout } = authStore;
  const [showModal, setShowModal] = useState<boolean>(false);
  // const [timer, setTimer] = useState<number>(20); // Timer state
  const timeoutRef = useRef<number | undefined>(undefined);
  const countdownRef = useRef<number | undefined>(undefined); // Countdown timer reference
  let t:any; // Countdown timer reference
  const history = useHistory();

  const resetStores = () => {
    authStore.reset();
    tenantStore.resetStore();
    userStore.reset();
  };

  const handleActivity = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      setShowModal(true);
      // setTimer(20); // Reset timer to 10 seconds
    }, 30*60*1000); // 1*10*1000
  }, []);

  const handleContinue = () => {
    clearInterval(t);
    setShowModal(false);
    handleActivity();
  };
  
  const handleLogout = () => {
    setShowModal(false);
    clearInterval(countdownRef.current);
    logout();
  };

  useEffect(()=>{
    if(showModal)
     t = setTimeout(()=>{
        logout();
    },20000)
  }, [showModal])

  useEffect(() => {
    if (logoutSuccess) {
      resetStores();
      resetLogout();
      clearInterval(t);
      toast.success(formatMessage('logout_successfully'));
      history.push(RoutesConstants.Login);
      setShowModal(false);
    }
  }, [logoutSuccess, history, resetStores, resetLogout]);

  useEffect(() => {
    if (logoutError) {
      toast.error(formatMessage(logoutError));
      resetLogout();
    }
  }, [logoutError, resetLogout]);

  useEffect(() => {
    const events: string[] = [
      'mousemove',
      'keydown',
      'mousedown',
      'touchstart',
      'click',
      'scroll',
      'wheel',
      'resize',
      'focus',
      'dblclick',
      'touchmove',
      'touchend',
      'touchcancel',
    ];

    // Attach event listeners to detect activity
    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    handleActivity();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (countdownRef.current) {
        clearInterval(countdownRef.current);
      }
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
    };
  }, [handleActivity]);

  return (
    <>
      <Modal show={showModal} centered size="sm">
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="inactivity_title" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <FormattedMessage id="inactivity_warning" /> 20 &nbsp;
            <FormattedMessage id="seconds" />.
          </p>
          <p className='mt-3'>
            <FormattedMessage id="continue_confirm" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light-primary"
            type="button"
            className="btn btn-primary"
            onClick={handleContinue}
          >
            <FormattedMessage id="button_yes" />
          </Button>
          <Button
            variant="light-danger"
            className="btn btn-danger"
            type="button"
            onClick={handleLogout}
          >
            <FormattedMessage id="button_no" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default observer(InactivityPopup);
