/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Madhav, Created Date:30-May-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect, useState } from "react";
import "./add-edit-image-status.css";
import { observer } from "mobx-react";
import { useStore } from "../../../contexts/store-provider";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";
import AddEditImageStatusForm from "../forms/add-edit-image-status-form";
import {
  parkingLotStatusOptions,
  parkingLotStatusOptionsArray,
} from "../../../constants/options.constant";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import AddEditParkingLotStatusImageForm from "../forms/add-edit-parking-lot-status-image-form";
import { cloneDeep } from "lodash";

interface IProps {
  id: number;
  modalClosed: () => void;
}
const AddEditImageSatus = (props: IProps) => {
  const { mediaStore, preferencesStore, cameraStore, parkingLotStore } = useStore();
  const { getParkingLotCameraAccess } = preferencesStore;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [updateInitial, setUpdateInitial] = useState<any>({});
  const {
    AddCameraService,
    statusImageDetails,
    GetParkingLotStatusImageById,
    statusImageByIdState,
    setParkingLotStatusImageState,
    updateParkingLotStatusImageState,
    resetUpdateParkingLotStatusImageState,
    resetParkingLotStatusImageDetail,
    resetSetParkingLotStatusImageState,
    UpdateParkingLotStatusImageService,
    SetParkingLotStatusImage,
  } = cameraStore;
  const {
    GetAllFolders,
    GetAllImages,
    getAllFolderState,
    allAvailableImages,
    getAllImagesState,
    allAvailableFolders,
    allStatusImageFolders,
    allAvailableTenantImages,
    resetGetAllImagesState,
  } = mediaStore;
  const { GetAllParkingLotCategoryService, allAvailableParkingLotCategory, allParkingCategories } = parkingLotStore;
  let initialValues: any = statusImageDetails;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    GetAllImages(getTenantAccessFromLocalStorage());
    GetAllFolders(getTenantAccessFromLocalStorage());
    GetAllParkingLotCategoryService(getTenantAccessFromLocalStorage(), props.id);
    if (props.id > 0) {
      GetParkingLotStatusImageById(props.id, getTenantAccessFromLocalStorage());
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "priceSchedulerMappingState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (setParkingLotStatusImageState.success) {
      toast.success(formatMessage("updated_success"));
      resetSetParkingLotStatusImageState();
      // resetUpdateParkingLotStatusImageState();
      onModalClosed();
    }
  }, [setParkingLotStatusImageState.success]);

  useEffect(() => {
    if (updateParkingLotStatusImageState.success) {
      // toast.success(formatMessage("updated_success"));
      // resetSetParkingLotStatusImageState();
      resetUpdateParkingLotStatusImageState();
      // onModalClosed();
    }
  }, [updateParkingLotStatusImageState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "priceSchedulerMappingState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (setParkingLotStatusImageState.error) {
      toast.error(formatMessage(setParkingLotStatusImageState.error));
      resetSetParkingLotStatusImageState();
    }
  }, [setParkingLotStatusImageState.error]);


  const onChangeInitialValues = (values:any, updateStatus: boolean) => {    
    let tempValues = cloneDeep(initialValues);
    tempValues.CategoryId = values.CategoryId;
    tempValues.FullImage = values.FullImage;
    tempValues.AvailImage = values.AvailImage;
    tempValues.ClosedImage = values.CloseImage;
    tempValues.CrowdedImage = values.CrowdedImage;
    tempValues.FolderId = values.FolderId;
    tempValues.Height = values.Height;
    tempValues.Width = values.Width;

     setUpdateInitial(tempValues);
     setIsEdit(updateStatus);
      
  }

  /**
   * This function adds the image when "Id < 0"
   * correponding function
   */

  const onSubmitForm = (values: any) => {
    let statusDataUpdate: any[] = [];
    let statusDataAdd: any[] = [];

    if(values.StatusDataUpdate?.length > 0){
      for (var i = 0; i < values.StatusDataUpdate?.length; i++) {
        statusDataUpdate.push(
          {
            ImageId: Number(values.StatusDataUpdate[i]?.AvailImageId),
            CategoryId: Number(values.StatusDataUpdate[i]?.CategoryId),
            ImageSource: values.StatusDataUpdate[i]?.AvailImageSource,
            FolderId: Number(values.StatusDataUpdate[i]?.FolderId),
            Width: Number(values.StatusDataUpdate[i]?.Width),
            Height: Number(values.StatusDataUpdate[i]?.Height),
            Status: 0,
          },
          {
            ImageId: Number(values.StatusDataUpdate[i]?.CrowdedImageId),
            CategoryId: Number(values.StatusDataUpdate[i]?.CategoryId),
            ImageSource: values.StatusDataUpdate[i]?.CrowdedImageSource,
            FolderId: Number(values.StatusDataUpdate[i]?.FolderId),
            Width: Number(values.StatusDataUpdate[i]?.Width),
            Height: Number(values.StatusDataUpdate[i]?.Height),
            Status: 1,
          },
          {
            ImageId: Number(values.StatusDataUpdate[i]?.FullImageId),
            CategoryId: Number(values.StatusDataUpdate[i]?.CategoryId),
            ImageSource: values.StatusDataUpdate[i]?.FullImageSource,
            FolderId: Number(values.StatusDataUpdate[i]?.FolderId),
            Width: Number(values.StatusDataUpdate[i]?.Width),
            Height: Number(values.StatusDataUpdate[i]?.Height),
            Status: 2,
          },
          {
            ImageId: Number(values.StatusDataUpdate[i]?.CloseImageId),
            CategoryId: Number(values.StatusDataUpdate[i]?.CategoryId),
            ImageSource: values.StatusDataUpdate[i]?.CloseImageSource,
            FolderId: Number(values.StatusDataUpdate[i]?.FolderId),
            Width: Number(values.StatusDataUpdate[i]?.Width),
            Height: Number(values.StatusDataUpdate[i]?.Height),
            Status: 3,
          }
        );
      }
    }
    if(values.StatusDataAdd?.length > 0){
      for (var i = 0; i < values.StatusDataAdd?.length; i++) {
        statusDataAdd.push(
          {
            CategoryId: Number(values.StatusDataAdd[i]?.CategoryId),
            ImageSource: values.StatusDataAdd[i]?.AvailImageSource,
            FolderId: Number(values.StatusDataAdd[i]?.FolderId),
            Width: Number(values.StatusDataAdd[i]?.Width),
            Height: Number(values.StatusDataAdd[i]?.Height),
            Status: 0,
          },
          {
            CategoryId: Number(values.StatusDataAdd[i]?.CategoryId),
            ImageSource: values.StatusDataAdd[i]?.CrowdedImageSource,
            FolderId: Number(values.StatusDataAdd[i]?.FolderId),
            Width: Number(values.StatusDataAdd[i]?.Width),
            Height: Number(values.StatusDataAdd[i]?.Height),
            Status: 1,
          },
          {
            CategoryId: Number(values.StatusDataAdd[i]?.CategoryId),
            ImageSource: values.StatusDataAdd[i]?.FullImageSource,
            FolderId: Number(values.StatusDataAdd[i]?.FolderId),
            Width: Number(values.StatusDataAdd[i]?.Width),
            Height: Number(values.StatusDataAdd[i]?.Height),
            Status: 2,
          },
          {
            CategoryId: Number(values.StatusDataAdd[i]?.CategoryId),
            ImageSource: values.StatusDataAdd[i]?.CloseImageSource,
            FolderId: Number(values.StatusDataAdd[i]?.FolderId),
            Width: Number(values.StatusDataAdd[i]?.Width),
            Height: Number(values.StatusDataAdd[i]?.Height),
            Status: 3,
          }
        );
      }
    }

    if (statusDataUpdate.length > 0) {
      var updateObj: any = {
        TenantId: getTenantAccessFromLocalStorage(),
        PlId: props.id,
        ImageStatusDetails: statusDataUpdate,
      };
      UpdateParkingLotStatusImageService(updateObj);
    }

    if (statusDataAdd.length >= 0) {
      var addObj: any = {
        TenantId: getTenantAccessFromLocalStorage(),
        PlId: props.id,
        ImageStatusDetails: statusDataAdd,
      };
      SetParkingLotStatusImage(addObj);
    }

  };
  /**
   * This function closes the pop up modal and reset getbrachDetails, addUpdate branch observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetParkingLotStatusImageDetail();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      {/* <AddEditImageStatusForm
        initialValues={isEdit ? updateInitial : initialValues}
        id={props.id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        onChangeInitialValues={onChangeInitialValues}
        allTenantImagesOptions={allAvailableTenantImages}
        allImageOptions={allAvailableImages}
        allFolderOptions={allStatusImageFolders}
        isLoading={
          updateParkingLotStatusImageState.inProgress ||
          setParkingLotStatusImageState.inProgress ||
          getAllFolderState.inProgress ||
          statusImageByIdState.inProgress ||
          getAllImagesState.inProgress
        }
      /> */}

<AddEditParkingLotStatusImageForm
        initialValues={isEdit == true ? updateInitial : initialValues}
        id={props.id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        onChangeInitialValues={onChangeInitialValues}
        allTenantImagesOptions={allAvailableTenantImages}
        allImageOptions={allAvailableImages}
        allFolderOptions={allStatusImageFolders}
        allAvailableCategoryOptions = {allAvailableParkingLotCategory}
        allCategories={allParkingCategories}
        isLoading={
          updateParkingLotStatusImageState.inProgress ||
          setParkingLotStatusImageState.inProgress ||
          getAllFolderState.inProgress ||
          statusImageByIdState.inProgress||
          getAllImagesState.inProgress
        }
      />
    </React.Fragment>
  );
};

export default observer(AddEditImageSatus);
