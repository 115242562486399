/*    
<summary>
   This file is used to provide initial values or state to the Add / Edit Parking Lot Data observables.
   Developer:Mohammad Saquib Khan, Created Date: 14-April-2024
</summary>
*/

export const initialState: any = {
  TenantId: 0,
  BranchId: 0,
  PlName: "",
  ParkingLotId: "",
  LinkoutAddress: "",
  ParkingLotInputTypeEnum: -1,
  ParkingLotOutputTypeEnum: -1,
  CurrentStatus: -1,
  Comment: "",
  ParkingLotsCategories: [
    {
      Category: 1,
      Description: ""
    },
    {
      Category: 2,
      Description: ""
    },
    {
      Category: 3,
      Description: ""
    },
    {
      Category: 4,
      Description: ""
    }
  ]
};
