import authStore, { AuthStore } from "./auth-store";
import branchStore, { BranchStore } from "./branch-store";
import parkingLotStore, { ParkingLotStore } from "./parking-lot-store";
import preferencesStore, { PreferencesStore } from "./preferences-store";
import tenantStore, { TenantStore } from "./tenant-store";
import userStore, { UserStore } from "./user-store";
import mediaStore, {MediaStore} from "./media-store"
import integratorStore, { IntegratorStore } from "./integrator-store";
import cameraStore, { CameraStore } from "./camera-store";
import priceSchedulerStore ,{ PriceSchedulerStore } from "./price-scheduler-store";
import logsStore ,{ LogsStore } from "./logs-store";
import linkbaseStore,{ LinkbaseStore } from "./linkbase-store";
import soracomStore ,{ SoracomAuthStore } from "./soracom-auth-store";
export type RootStore = {
  authStore: AuthStore;
  preferencesStore: PreferencesStore;
  userStore: UserStore;
  tenantStore: TenantStore;
  branchStore: BranchStore;
  parkingLotStore: ParkingLotStore;
  mediaStore:MediaStore;
  cameraStore: CameraStore;
  integratorStore: IntegratorStore;
  priceSchedulerStore: PriceSchedulerStore;
  logsStore: LogsStore;
  linkbaseStore: LinkbaseStore;
  soracomStore: SoracomAuthStore;
};

const rootStore: RootStore = {
  authStore,
  preferencesStore,
  userStore,
  tenantStore,
  branchStore,
  parkingLotStore,
  mediaStore,
  cameraStore,
  integratorStore,
  priceSchedulerStore,
  logsStore,
  linkbaseStore,
  soracomStore
};

export default rootStore;
