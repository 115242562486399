/*    
<summary>
   This class component is all about Managing user data functionality.
   Developer:Mohammad Saquib Khan, Created Date:29-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import { IObservableInitialState, IOption } from "../../models/ICommon";
import { ICommonState } from "../../models/state/ICommonState";
import { ITenantState } from "../../models/state/ITenantState";
import {
  ITenantList,
  ITenantListVM,
  ITenantVM,
} from "../../models/response/ITenantResponse";
import * as baseService from '../service/base-service';
import { initialState as allUserLogsInitialState } from "../initialState/get-all-user-logs-state";
import { initialState as allSystemLogsInitialState } from "../initialState/get-all-system-logs-state";
import { initialState as addTenantInitialState } from "../initialState/add-tenant-state";
import toast from "react-hot-toast";
import URLConstants from "../../constants/url-constants";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { formatMessage } from "../../translations/format-message";
import IAddTenant, { IUpdateTenant } from "../../models/forms/IAddUpdateTenant";
import { IUserLogsVM } from "../../models/response/IUserLogsResponse";
import config from "../../helpers/config-helper";
import moment from "moment";
import requestTypeEnum from "../../constants/enums/request-type-enum";
import targetTypeEnum from "../../constants/enums/target-type-enum";
import { ISystemLogVM, ISystemLogsVM } from "../../models/response/ISystemLogsResponse";
import responseTypeEnum from "../../constants/enums/response-type-enum";

const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT_WITH_MILLISECONDS;

export class LogsStore implements ICommonState {
  inProgress = false;
  error = "";

  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  userLogsList: IUserLogsVM = allUserLogsInitialState;
  systemLogsList: ISystemLogsVM = allSystemLogsInitialState;
  userLog: any = undefined;

  getAllUserLogsState = {...this.initialStateValue};
  getAllSystemLogsState = {...this.initialStateValue};

  constructor() {
    makeObservable(this, {
      inProgress: observable,
      userLogsList: observable,
      systemLogsList: observable,
      userLog: observable,
      getAllUserLogsState: observable,
      getAllSystemLogsState: observable,

      GetUserLogsListService: action,
      GetUserLogsFilterListService: action,
      GetSystemLogsListService:action,
      GetSystemLogsFilterListService: action,

      resetStore: action,
      reset: action,
    
      allUserLogsList: computed,
      allSystemLogsList: computed
    });
  }

  /**
   * This function is used to get tenants list with pagination by calling API.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
  GetUserLogsListService = (pageNumber: number, pageSize: number) => {
    this.getAllUserLogsState.inProgress = true;
    const url =
      URLConstants.UserLogsList +
      "?PageNo=" +
      pageNumber +
      "&PageSize=" +
      pageSize;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IUserLogsVM>>) => {
        if (response.data.Error) {
          this.getAllUserLogsState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.userLogsList = response.data.Data;
          this.getAllUserLogsState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getAllUserLogsState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to map deviceDataList to allTenantslist suitable for Grid Component.
   * @returns Initial Tenant Details
   */
  get allUserLogsList(): any[] {
    if (this.userLogsList && this.userLogsList?.Logs?.length > 0) {
      return this.userLogsList?.Logs.map((logs:any) => {
        return {
            DateTime: moment(logs.ActionTimestamp).format(dateTimeFormat),
            UserId: logs.UserId,
            Company: logs.Company,
            Branch: logs.Branch,
            ParkingLot: logs.Parking,
            Target: targetTypeEnum[logs.Target],
            Type:  requestTypeEnum[logs.Type],
            Content: logs.Content,
        };
      });
    }
    return [];
  }

  GetUserLogsFilterListService = (pageNumber: number, pageSize: number, userId: string, company: string) => {
    this.getAllUserLogsState.inProgress = true;
    const url =
      URLConstants.UserLogsFilterList +
      "?PageNo=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&userid=" +
      userId +
      "&company=" +
      company;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IUserLogsVM>>) => {
        if (response.data.Error) {
          this.getAllUserLogsState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.userLogsList = response.data.Data;
          this.getAllUserLogsState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getAllUserLogsState.inProgress = false;
        })
      );
  };

    /**
   * This function is used to get tenants list with pagination by calling API.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
    GetSystemLogsListService = (pageNumber: number, pageSize: number, tenantId: number) => {
      this.getAllSystemLogsState.inProgress = true;
      const url =
        URLConstants.SystemLogsList +
        "?PageNo=" +
        pageNumber +
        "&PageSize=" +
        pageSize +
        "&TenantId=" +
        tenantId;
      return baseService
        .getRequest(url)
        .then((response: IApiResponse<IApiSuccessResponse<ISystemLogsVM>>) => {
          if (response.data.Error) {
            this.getAllSystemLogsState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.systemLogsList = response.data.Data;
            this.getAllSystemLogsState.success = true;
          }
        })
        .catch((err: string) => {
          toast.error(formatMessage(err));
        })
        .finally(
          action(() => {
            this.getAllSystemLogsState.inProgress = false;
          })
        );
    };
  
    /**
     * This function is used to map deviceDataList to allTenantslist suitable for Grid Component.
     * @returns Initial Tenant Details
     */
    get allSystemLogsList(): any[] {
      if (this.systemLogsList && this.systemLogsList?.Logs?.length > 0) {
        return this.systemLogsList?.Logs.map((logs:any) => {
          return {
              DateTime: moment(logs.ActionTimestamp).format(dateTimeFormat),
              UserId: logs.UserId,
              Company: logs.Company,
              Branch: logs.Branch,
              ParkingLot: logs.Parking,
              Target: formatMessage(targetTypeEnum[logs.Target]),
              Type:  requestTypeEnum[logs.Type],
              Result:  responseTypeEnum[logs.Responsetype],
              Data: logs.Data,
          };
        });
      }
      return [];
    }

    GetSystemLogsFilterListService = (pageNumber: number, pageSize: number, userId: string, branchName: string, parkingLotName: string ,tenantId: number) => {
      this.getAllSystemLogsState.inProgress = true;
      const url =
        URLConstants.SystemLogsFilterList +
        "?PageNo=" +
        pageNumber +
        "&PageSize=" +
        pageSize +
        "&userid=" +
        userId +
        "&branch=" +
        branchName +
        "&parking="+
        parkingLotName +
        "&tenantid=" +
        tenantId;
      return baseService
        .getRequest(url)
        .then((response: IApiResponse<IApiSuccessResponse<ISystemLogsVM>>) => {
          if (response.data.Error) {
            this.getAllSystemLogsState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.systemLogsList = response.data.Data;
            this.getAllSystemLogsState.success = true;
          }
        })
        .catch((err: string) => {
          toast.error(formatMessage(err));
        })
        .finally(
          action(() => {
            this.getAllSystemLogsState.inProgress = false;
          })
        );
    };
  

  /**
   * This function is used to reset observables to their initial values.
   * @returns
   */
  reset = () => {
    this.error = "";
    this.inProgress = false;
    this.getAllUserLogsState = {...this.initialStateValue};
    this.getAllSystemLogsState = {...this.initialStateValue};
  };

  /**
   * This function is used to reset all store observables to their initial values.
   * @returns
   */
  resetStore = () => {
    this.error = "";
    this.inProgress = false;
    this.getAllUserLogsState = {...this.initialStateValue};
    this.getAllSystemLogsState = {...this.initialStateValue};
  };
}

export default new LogsStore();
