/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Mohammad Saquib Khan, Created Date:28-August-2024
</summary>
<returns>Returns JSX</returns>
*/

import "./add-update-linkbase-out.css";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../contexts/store-provider";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import _ from "lodash";
import AddUpdateLinkbaseOutForm from "../forms/add-update-linkbase-out-form";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { IOption } from "../../../models/ICommon";
interface IProps {
  id: number;
  parkingLotId: string;
  modalClosed: () => void;
}

const AddUpdateLinkbaseOut = (props: IProps) => {
  const {
    preferencesStore,
    linkbaseStore,
    cameraStore,
    parkingLotStore,
    soracomStore,
  } = useStore();
  const {
    allAvailableLinkbaseOutRelayTypes,
    getLinkbaseOut,
    GetLinkbaseInByParkingLotId,
    GetLinkbaseOutRelayByRelayTypeService,
    getLinkBaseOutRelayByRelaTypeState,
    getLinkbaseOutRelayByRelayType,
    allAvailableLinkbaseOutTargetTypes,
    AddLinkBaseOutService,
    GetLinkbaseOutBySimId,
    getLinkbaseOutBySimIdState,
    resetAddLinkBaseOutServiceState,
    UpdateLinkBaseOutService,
    addUpdateLinkbaseOutState,
    resetLinkbaseOut,
  } = linkbaseStore;
  const { GetAllCameraSlotsService, allAvailableCameraSlots } = cameraStore;
  const { getLinkBaseOutAccess } = preferencesStore;
  const { GetAllParkingLotCategoryService, allParkingLotCategory, allAvailableParkingLotCategorySlotType } =
    parkingLotStore;
  const { GetAllSoracom, allAvailableSoracom, allSoracomListState } =
    soracomStore;
  const initialValues = getLinkbaseOut;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    GetAllSoracom(getTenantAccessFromLocalStorage());
    if (props.id > -1) {
      GetLinkbaseOutBySimId(props.id, getTenantAccessFromLocalStorage());
    }
    GetAllParkingLotCategoryService(
      getTenantAccessFromLocalStorage(),
      getLinkBaseOutAccess.plId
    );
    GetAllCameraSlotsService(getTenantAccessFromLocalStorage(),getLinkBaseOutAccess.plId);
    GetLinkbaseInByParkingLotId(
      getLinkBaseOutAccess.plId,
      getTenantAccessFromLocalStorage()
    );
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateLinkbaseOutState.success) {
      toast.success(formatMessage("added_success"));
      onModalClosed();
    }
  }, [addUpdateLinkbaseOutState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "userDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateLinkbaseOutState.error) {
      toast.error(formatMessage(addUpdateLinkbaseOutState.error));
      resetAddLinkBaseOutServiceState();
    }
  }, [addUpdateLinkbaseOutState.error]);

  const getData = (id: number) => {
    GetLinkbaseOutRelayByRelayTypeService(id);
  };

  const parkingLotIdTargetOption = (): IOption[] => {
    const parkingLotOption: IOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: false,
      },
      {
        id: getLinkBaseOutAccess.plId,
        value: props.parkingLotId,
        disabled: false,
      },
    ];
    return parkingLotOption;
  };

  /**
   * This function adds the Linkbase Out when "Id < 0" and updates the Existing Linkbase Out when Id > 0 by providing form values to the
   * correponding function
   */
  const onSubmitForm = (values: any) => {
    let IsSimNumberChanged: boolean = false;
    let IsLinkbaseChanged: boolean = false;
    let innerObj: any = [
      {
        PlId: getLinkBaseOutAccess.plId,
        SimId: values.SimId?.trim(),
        Comment: values.Comment,
        RelayType: Number(values.RelayType),
        SoracomId: Number(values.SoracomId),
        LinkBaseOutDetails: [],
      },
    ];

    let addUpdateRelayData = expandRelayData(values);
    let oldRelayData = expandRelayCheckIfChanged(initialValues);

    if (!_.isEqual(oldRelayData, addUpdateRelayData)) {
      IsLinkbaseChanged = true;
    }

    if (
      values.SimId?.trim() !== initialValues.SimId?.trim() ||
      values.RelayType !== initialValues.RelayType ||
      values.Comment !== initialValues.Comment ||
      Number(values.SoracomId) !== Number(initialValues.SoracomId)
    ) {
      IsSimNumberChanged = true;
    }

    if (values.IsLinkbaseOutAdded == false) {
      innerObj[0].LinkBaseOutDetails = addUpdateRelayData;
      AddLinkBaseOutService({
        TenantId: getTenantAccessFromLocalStorage(),
        LinkBaseOutSimRegistrationDetails: innerObj,
      });
    } else {
      let updateObj: any = {
        LinkBaseOutSimId: values.LinkBaseOutSimId,
        TenantId: getTenantAccessFromLocalStorage(),
        PlId: getLinkBaseOutAccess.plId,
        SimId: values.SimId?.trim(),
        SoracomId: Number(values.SoracomId),
        Comment: values.Comment,
        RelayType: Number(values.RelayType),
        IsSimNumberChanged: IsSimNumberChanged,
        IsLinkbaseChanged: IsLinkbaseChanged,
        UpdateLinkBaseOutDetails: [],
      };
      updateObj.UpdateLinkBaseOutDetails = addUpdateRelayData;
      UpdateLinkBaseOutService(updateObj);
    }
  };

  const expandRelayData = (values: any) => {
    let linkbaseOut: any[] = [];
    for (let i = 0; i < values?.LinkBaseOutRelays?.length; i++) {
      if (values.LinkBaseOutRelays[i]?.Disabled == false) {
        linkbaseOut.push({
          PlId: getLinkBaseOutAccess.plId,
          RelayValue: values.LinkBaseOutRelays[i].RelayValue,
          RelayStatus: values.LinkBaseOutRelays[i].RelayStatus,
          LightType: values.LinkBaseOutRelays[i].LightType,
          TargetType: setTargetType(
            values.LinkBaseOutRelays[i].LightType,
            values
          ),
          Target: setTarget(values.LinkBaseOutRelays[i].LightType, values),
          Comment: values.LinkBaseOutRelays[i].Comment,
        });
      }
    }
    return linkbaseOut;
  };

  const expandRelayCheckIfChanged = (values: any) => {
    let linkbaseOut: any[] = [];
    for (let i = 0; i < values?.LinkBaseOutRelays?.length; i++) {
      if (values.LinkBaseOutRelays[i]?.Disabled == false) {
        linkbaseOut.push({
          PlId: getLinkBaseOutAccess.plId,
          RelayValue: values.LinkBaseOutRelays[i].RelayValue,
          RelayStatus: values.LinkBaseOutRelays[i].RelayStatus,
          LightType: values.LinkBaseOutRelays[i].LightType,
          TargetType: Number(values.LinkBaseOutRelays[i].TargetType),
          Target: Number(values.LinkBaseOutRelays[i].Target),
          Comment: values.LinkBaseOutRelays[i].Comment,
        });
      }
    }
    return linkbaseOut;
  };

  const setTarget = (lightType: number, values: any) => {
    if (lightType == 0) {
      return Number(values.LinkBaseOutRelays[0].Target);
    } else if (lightType == 1) {
      return Number(values.LinkBaseOutRelays[4].Target);
    } else if (lightType == 2) {
      return Number(values.LinkBaseOutRelays[8].Target);
    } else {
      return Number(values.LinkBaseOutRelays[10].Target);
    }
  };

  const setTargetType = (lightType: number, values: any) => {
    if (lightType == 0) {
      return Number(values.LinkBaseOutRelays[0].TargetType);
    } else if (lightType == 1) {
      return Number(values.LinkBaseOutRelays[4].TargetType);
    } else if (lightType == 2) {
      return Number(values.LinkBaseOutRelays[8].TargetType);
    } else {
      return Number(values.LinkBaseOutRelays[10].TargetType);
    }
  };

  /**
   * This function closes the pop up modal and reset getbrachDetails, addUpdate branch observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetLinkbaseOut();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <Modal
        className="linkbase-modal fancy-modal"
        size="lg"
        show={true}
        centered={true}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.id > -1 ? (
              <FormattedMessage id="update_linkbase_out" />
            ) : (
              <FormattedMessage id="add_linkbase_out" />
            )}
          </Modal.Title>
          <button
            onClick={onModalClosed}
            data-testid="Close"
            className="Crossicons"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="modal-body">
          <AddUpdateLinkbaseOutForm
            id={props.id}
            initialValues={initialValues}
            parkingLotId={props.parkingLotId}
            soracomOptions={allAvailableSoracom}
            slotTypeTarget={allAvailableParkingLotCategorySlotType}
            parkingLotTarget={parkingLotIdTargetOption()}
            cameraSlotTarget={allAvailableCameraSlots}
            allLinkBaseOutRelays={getLinkbaseOutRelayByRelayType}
            sensorTypeOptions={allAvailableLinkbaseOutRelayTypes}
            targetTypeOptions={allAvailableLinkbaseOutTargetTypes}
            getData={getData}
            submitHandler={onSubmitForm}
            onModalClosed={onModalClosed}
            isDataLoading={
              getLinkBaseOutRelayByRelaTypeState.inProgress ||
              getLinkbaseOutBySimIdState.inProgress ||
              allSoracomListState.inProgress
            }
            isLoading={getLinkBaseOutRelayByRelaTypeState.inProgress || addUpdateLinkbaseOutState.inProgress}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default observer(AddUpdateLinkbaseOut);
