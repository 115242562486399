/*    
<summary>
  This functional component is used to "(Add / Update) Signboard image".
  Developer:Mohammad Saquib Khan, Created Date:24-July-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Form, Formik, Field } from "formik";
import "./upload-signboard-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import config from "../../../helpers/config-helper";
import noMessageIcon from "../../../assets/Images/svg/018-empty.svg";
import { UploadImageMediaValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormikFormImageFileInput from "../../../shared-components/FormikFormHandlers/FormikFormImageFileInput";
import { bytesToMegabytes } from "../../../constants/constants";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";

interface IProps {
  id: number;
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  isLoading: boolean;
  initialValues: any;
}

const UploadSignBoardForm = (props: IProps) => {
  const [fileData, setFileData] = useState<any>();
  const [base64, setBase64] = useState<any>();
  const [mediaExtension, setMediaExtension] = useState<any>();

  return (
    <>
      <Modal
        className="threshold-modal fancy-modal update-signboard"
        show={true}
        size="lg"
        centered
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.id == 0 ? (
              <FormattedMessage id="add_signboard" />
            ) : (
              <FormattedMessage id="update_signboard" />
            )}
            <button
              onClick={props.onModalClosed}
              disabled={props.isLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{ ImageFile: "" }}
          enableReinitialize
          validationSchema={UploadImageMediaValidateSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            if(bytesToMegabytes(fileData?.size) > 5.1) {
              toast.error(formatMessage('image_size_validation'));
            }
            else{
              props.submitHandler({ ...values, Base64: base64 });
            }
          }}
        >
          {({ values }) => {
            return (
              <Form className="user-form">
                {props.isLoading ? (
                  <FormLoader loading={props.isLoading} />
                ) : null}
                <Modal.Body>
                  <div className="row">
                    <div className="col-12">
                      <label className="label-style">
                        <strong>
                          <FormattedMessage id="current_signboard" />
                        </strong>
                      </label>
                      {props.initialValues?.IsSignBoardAvailable == true ? (
                        <div className="image_box signboard_images">
                          <img
                            src={props.initialValues?.ImageSource}
                            className="img-fluid img-thumbnail"
                          />
                        </div>
                      ) : (
                        <>
                          <div className="message text-center signBoard">
                            <img
                              src={noMessageIcon}
                              alt=""
                              className="no_message"
                            />
                            <p className="noData text-muted text-center h5 mb-0">
                              <FormattedMessage id="no_signboard" />
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-12 mt-3">
                      <label className="label-style">
                        <strong>
                          {props.initialValues?.IsSignBoardAvailable == true ? (
                            <FormattedMessage id="update_signboard" />
                          ) : (
                            <FormattedMessage id="add_signboard" />
                          )}
                        </strong>
                      </label>
                      <div className="form-group mb-1 mt-3">
                        <Label
                          className="label-style"
                          required={true}
                          label="upload_sign_board_image"
                        />
                        <Field
                          className="form-control"
                          id="upload_media"
                          name="ImageFile"
                          setFile={setFileData}
                          setBase64={setBase64}
                          setExtension={setMediaExtension}
                          accept=".jpg, .jpeg, .png, .svg"
                          as={FormikFormImageFileInput}
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <div className="modal-footer">
                  <div className="row tab-action g-0">
                    <div className="col-12">
                      <Button
                        variant="light-danger"
                        type="button"
                        onClick={props.onModalClosed}
                        disabled={props.isLoading}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Button>
                      <LoaderButton
                        type="submit"
                        isLoading={props.isLoading}
                        text="button_save"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default UploadSignBoardForm;
