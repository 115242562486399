/*    
<summary>
  This functional component "LoginForm" provides form to login into application on the basis of the credentials provided.
  Developer:Aashish Singh, Created Date:28-Aug-2022, Last Updated By: Mohammad Saquib Khan, Updated Date: 10-Feb-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import monozlogo from "../../../assets/Images/monoZ_Logo.png";
import loginBackground from "../../../assets/Images/backgroundLoginSvg.svg"
import { FormattedMessage } from "react-intl";
import ILogin from "../../../models/forms/ILogin";
import hucksterLogo from "../../../assets/Images/logo.svg";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormCheckBox from "../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import { LoginValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import { useStore } from "../../../contexts/store-provider";
import { useHistory } from "react-router-dom";

interface IProps {
  loginHandler: (data: ILogin) => void;
  initialValues: ILogin;
  isLoading: boolean;
}

let LoginForm = (props: IProps) => {
  const history = useHistory();
  const { preferencesStore } = useStore();
  const [showHidePassword, setShowHidePassword] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<{
    language: string;
    isLanguageChanged: boolean;
  }>({ language: preferencesStore.language, isLanguageChanged: false });

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "selectedLanguage.isLanguageChanged" of the useEffect changed.
   */
  useEffect(() => {
    if (selectedLanguage.isLanguageChanged) {
      preferencesStore.changeLanguage(selectedLanguage.language);
      setSelectedLanguage({ ...selectedLanguage, isLanguageChanged: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage.isLanguageChanged]);

  return (
    <>
      <Formik
        initialValues={props.initialValues}
        validationSchema={LoginValidateSchema}
        validateOnBlur={false}
        onSubmit={async (values) => {
          props.loginHandler(values);
        }}
      >
        <Form className="login">
          <div className="form-login">
          <figure className="logoLogin">
            <img src={hucksterLogo} alt="logo" className="img-fluid"></img>
          </figure>
          <div className="svg-bg">
          <img src={loginBackground} alt="loginBackground" className="img-fluid login-wave"/>
          </div>
          <div className="loginBox">
            <div className="form-group">
              <Label
                required={true}
                label="user_label_login_id"
                htmlFor="email"
              />
              <Field
                data-testid="testUsername"
                className="form-control"
                name="email"
                type="text"
                invalidcharacter={[" "]}
                placeholder="user_label_login_id"
                validcharacter={[
                  "-",
                  "_",
                  "[",
                  "]",
                  "(",
                  ")",
                  ":",
                  "#",
                  "@",
                  ".",
                ]}
                as={FormikFormInput}
              />
                    {/* <svg width="30"    className="user_login"
                height="30"  id="_レイヤー_6" data-name="レイヤー 6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">


                <g>
                  <circle cx="14.95" cy="7.61" r="3.75" />
                  <path  d="M14.95,12.08c-2.46,0-4.47-2.01-4.47-4.47s2-4.47,4.47-4.47,4.47,2.01,4.47,4.47-2,4.47-4.47,4.47Zm0-7.5c-1.67,0-3.03,1.36-3.03,3.03s1.36,3.03,3.03,3.03,3.03-1.36,3.03-3.03-1.36-3.03-3.03-3.03Z" />
                </g>
                <path  d="M24.95,26.86h-3v-.8c0-3.2-.91-7.15-2.27-9.84-.52-1.04-1.75-.95-3.41-.72-1.04,.15-1.75,.13-2.68-.01-1.63-.24-2.83-.34-3.34,.65-1.27,2.48-2.16,6.05-2.29,9.12h9.49c-.09-1.15-.3-3.27-.76-4.73l1.52-.48c.72,2.3,.87,5.83,.88,5.97l.03,.83H6.34v-.8c0-3.52,.98-7.71,2.49-10.65,1.07-2.09,3.44-1.73,5-1.5,.84,.13,1.39,.13,2.22,0,1.59-.22,3.98-.55,5.06,1.58,1.37,2.72,2.3,6.47,2.43,9.76h1.42v1.6Z" />
              </svg> */}
            </div>
            <div className="showHideOuterDiv form-group">
              <Label
                required={true}
                label="user_label_password"
                htmlFor="password"
              />
              <Field
                data-testid="testPassword"
                className="form-control"
                name="password"
                type={showHidePassword ? "text" : "password"}
                invalidcharacter={[" "]}
                validcharacter={[
                  "~",
                  "`",
                  "!",
                  "@",
                  "#",
                  "$",
                  "%",
                  "^",
                  "&",
                  "*",
                  "(",
                  ")",
                  "_",
                  "-",
                  "+",
                  "=",
                  "{",
                  "[",
                  "}",
                  "]",
                  "|",
                  "\\",
                  ":",
                  ";",
                  "'",
                  '"',
                  "<",
                  ",",
                  ">",
                  ".",
                  "?",
                  "/",
                ]}
                placeholder="user_label_password"
                as={FormikFormInput}
              />

              {showHidePassword ? (
                <svg
                  type="button"
                  onClick={() => {
                    setShowHidePassword(!showHidePassword);
                  }}
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  stroke="#000"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="showHidePass css-i6dzq1"
                >
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              ) : (
                <svg
                  type="button"
                  onClick={() => {
                    setShowHidePassword(!showHidePassword);
                  }}
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  stroke="#000"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="showHidePass css-i6dzq1"
                >
                  <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                  <line x1="1" y1="1" x2="23" y2="23"></line>
                </svg>
              )}
            </div>

            <div className="loginCheckbox">
              <label className="with-label-checkbox">
                <Field
                  data-testid="testRememberMe"
                  className="form-control"
                  name="rememberMe"
                  type="checkbox"
                  id="inlineFormCheck"
                  as={FormikFormCheckBox}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <h4 className="remember checkbox-label">
              <FormattedMessage id="remember_me" />
            </h4>
            {/* <div className="checkbox loginCheckbox">
              <label className="withlabel-checkbox1">
                <Field
                  data-testid="testRememberMe"
                  className="form-control"
                  name="rememberMe"
                  type="checkbox"
                  id="inlineFormCheck"
                  as={FormikFormCheckBox}
                />
                
                <span className="cr">           
                  <span className="checkmark"></span>
                </span>
              </label>
              <h4 className="remember">
                <FormattedMessage id="remember_me" />
              </h4>
            </div> */}

            <LoaderButton
              type="submit"
              className="btn btn-blue btn btn-primary"
              isLoading={props.isLoading}
              text="login"
            />
          </div>
        
          </div>
          <figure className="logoMonoz">
            <span className="powered text-white">Powered by</span>
            <img src={monozlogo} alt="logo" className="img-fluid"></img>
          </figure>
        </Form>
      </Formik>
    </>
  );
};

export default LoginForm;
