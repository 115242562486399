/*    
<summary>
  This functional component "UserProfile" provides user setting functionality like changing language with the help "setting" tab props like initial values.
  Developer:Aashish Singh, Created Date:28-Aug-2022, Last Updated By: Mohammad Saquib Khan, Updated Date: 13-Dec-2022
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./user-profile.css";
import { useStore } from "../../../contexts/store-provider";
import Settings from "../forms/settings";
import { formatMessage } from "../../../translations/format-message";

interface IProps {
  userName: string;
  userType: string;
  onModalClosed: () => void;
}

const UserProfile: React.FC<IProps> = (props) => {
  const { authStore } = useStore();
  const { getUserType, getEmail } = authStore;
  const ControlledTabs = () => {
    const [key, setKey] = useState<string>("Settings");

    return (
      <div className="user-profile-popup">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k: any) => setKey(k)}
          className="modal-body pb-0"
        >
          <Tab eventKey="Settings" title={formatMessage("")}>
            {/* <Settings modalClosed={props.onModalClosed} /> */}
          </Tab>
        </Tabs>
      </div>
    );
  };

  return (
    <Modal
      className="add-profile-modal "
      show={true}
      centered
      onHide={props.onModalClosed}
    >
      <Modal.Header
        data-testid="closebtn"
        onClick={props.onModalClosed}
        closeButton
      >
        <Modal.Title>
          <FormattedMessage id="user_profile" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <div className="row">
            <div className="col-2">
              <div className="profile_modal_icon">
                <svg width="30" height="30" fill="#fff" id="_レイヤー_6" data-name="レイヤー 6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><g><circle cx="14.95" cy="7.61" r="3.75"></circle><path fill="#fff" d="M14.95,12.08c-2.46,0-4.47-2.01-4.47-4.47s2-4.47,4.47-4.47,4.47,2.01,4.47,4.47-2,4.47-4.47,4.47Zm0-7.5c-1.67,0-3.03,1.36-3.03,3.03s1.36,3.03,3.03,3.03,3.03-1.36,3.03-3.03-1.36-3.03-3.03-3.03Z"></path></g><path fill="#fff" d="M24.95,26.86h-3v-.8c0-3.2-.91-7.15-2.27-9.84-.52-1.04-1.75-.95-3.41-.72-1.04,.15-1.75,.13-2.68-.01-1.63-.24-2.83-.34-3.34,.65-1.27,2.48-2.16,6.05-2.29,9.12h9.49c-.09-1.15-.3-3.27-.76-4.73l1.52-.48c.72,2.3,.87,5.83,.88,5.97l.03,.83H6.34v-.8c0-3.52,.98-7.71,2.49-10.65,1.07-2.09,3.44-1.73,5-1.5,.84,.13,1.39,.13,2.22,0,1.59-.22,3.98-.55,5.06,1.58,1.37,2.72,2.3,6.47,2.43,9.76h1.42v1.6Z"></path></svg>
              </div>
            </div>
            <div className="col-10">
              <div className="bootbox-body user-details">
                <h3 className="m-0">{getEmail ? getEmail : ""}</h3>
                <small className="text-muted" >
                  {getUserType ? getUserType : ""}
                </small>
              </div>
            </div>
          </div>
        <Settings modalClosed={props.onModalClosed} />
      </Modal.Body>
    </Modal>
  );
};

export default UserProfile;
