/*    
<summary>
  This functional component shows the configuration details.
  Developer:Aashish Singh, Created Date: 09-Oct-2023
</summary>
<returns>Returns JSX</returns>
*/
import "./parking-lot.css";
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { formatMessage } from "../../translations/format-message";
import { useStore } from "../../contexts/store-provider";
import userTypeEnum from "../../constants/enums/user-type-enum";
import { parkingLotStatus } from "../../constants/enums/parking-lot-status-enum";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import scheduleTypeEnum from "../../constants/enums/schedule-type-enum";
import toast from "react-hot-toast";
import { getTenantAccessFromLocalStorage } from "../../helpers/localstorage-helper";
import categoryEnum from "../../constants/enums/category-enum";
import { slotStatus } from "../../constants/enums/slot-status-enum";
interface IProps {
  selectedData: any;
}

const ParkingLotDetails = (props: IProps) => {
  const { parkingLotStore, priceSchedulerStore, preferencesStore } = useStore();
  const {
    currentScheduleDetails,
    GetCurrentScheduleServiceById,
    getCurrentScheduleState,
    GetSignBoardService,
    getSignBoardImage,
    getSignBoardState,
  } = priceSchedulerStore;
  const { getTenantAccess } = preferencesStore;

  const {
    getParkingLotStatusState,
    GetParkingLotStatusService,
    parkingLotStatusListDetails,
  } = parkingLotStore;

  useEffect(() => {
    if (props.selectedData.PlId > 0) {
      GetSignBoardService(
        props.selectedData.PlId,
        getTenantAccessFromLocalStorage()
      );
      GetParkingLotStatusService(
        getTenantAccessFromLocalStorage(),
        props.selectedData.PlId
      );
      GetCurrentScheduleServiceById(
        props.selectedData.PlId,
        getTenantAccessFromLocalStorage()
      );
    }
  }, [props.selectedData]);

  const handleCopyClick = (url: any) => {
    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toast.success(formatMessage("url_copied"));
  };

  const handleOpenSignBoardImage = () => {
    // Open the base64 string in a new tab
    const newWindow = window.open();
    if (newWindow) {
      newWindow.document.write(`<img src="${getSignBoardImage?.ImageSource}" />`);
      newWindow.document.close();
    }
  };

  return (
    <React.Fragment>
      {getParkingLotStatusState.inProgress ? (
        <FormLoader loading={getParkingLotStatusState.inProgress} />
      ) : null}
      {getCurrentScheduleState.inProgress ? (
        <FormLoader loading={getCurrentScheduleState.inProgress} />
      ) : null}
      {getSignBoardState.inProgress ? (
        <FormLoader loading={getSignBoardState.inProgress} />
      ) : null}
      <div className="p-3">
        <div className="row">
          <div className="col-6">
            <label className="text-muted bold">
              <FormattedMessage id={"parking_lot_name"} />
            </label>
            <p className="mw-100">
              {props?.selectedData?.PlName}
              {/* {"<Current Schedule>"} */}
            </p>
          </div>

          <div className="col-6">
            <label className="text-muted bold">
              <FormattedMessage id={"current_status"} />
            </label>
            <p className="mw-100">
              <span
                className={`badge-parking fs-6 ${
                  props.selectedData.CurrentStatus == parkingLotStatus.Available
                    ? "badge-light-success"
                    : props.selectedData.CurrentStatus ==
                      parkingLotStatus.Closed
                    ? "badge-light-dark"
                    : props.selectedData.CurrentStatus ==
                      parkingLotStatus.Crowded
                    ? "badge-light-secondary"
                    : props.selectedData.CurrentStatus == parkingLotStatus.Full
                    ? "badge-light-danger"
                    : ""
                }`}
              >
                {formatMessage(
                  parkingLotStatus[props.selectedData.CurrentStatus]
                )}
              </span>
              {/* <span className="badge badge-danger fs-6"> {parkingLotStatus[props.selectedData.CurrentStatus]}</span> */}
            </p>
          </div>
        </div>
        {/* <div className="row pt-2">
          <div className="col-6">
            <label className="text-muted bold">
              {formatMessage("html")}
            </label>
          </div>
          <div className="col-6">
            <div className="mw-100 d-flex">
              <button onClick={() => handleCopyClick(`${process.env.REACT_APP_BASE_URL}PL/${getTenantAccess.orgId}/${props.selectedData.PlId}`)}>
                <svg
                  id="Layer_2"
                  height="21"
                  viewBox="0 0 24 24"
                  width="21"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#0091ff"
                >
                  <path d="m5.4521 22h9.0957c1.7485 0 3.1822-1.3118 3.4064-3h.5936c1.9034 0 3.4522-1.5488 3.4522-3.4521v-10.0958c0-1.9033-1.5488-3.4521-3.4521-3.4521h-9.0958c-1.9033 0-3.4521 1.5488-3.4521 3.4521v.5479h-.5479c-1.9033 0-3.4521 1.5488-3.4521 3.4521v9.0957c0 1.9034 1.5488 3.4522 3.4521 3.4522zm2.5479-16.5479c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v10.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-.5479v-7.5479c0-1.9033-1.5488-3.4521-3.4521-3.4521h-6.5479zm-4 4c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v9.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-9.0958c-.8007 0-1.4521-.6514-1.4521-1.4521z" />
                </svg>
              </button>
              <p>{`${process.env.REACT_APP_BASE_URL}PL/${getTenantAccess.orgId}/${props.selectedData.PlId}`}</p>
            </div>
          </div>
        </div> */}

        <div className="row mt-3 ">
          <strong>
            <FormattedMessage id="parking_lot_status" />
          </strong>
          <div className="lg-12">
            <div className="tableDesign tableDevicesPanel table-status mt-1">
              <div className="table-responsive" data-testid="table">
                <table className="table table-bordered">
                  <thead>
                    <th>
                      <FormattedMessage id="category" />
                    </th>
                    <th>
                      <FormattedMessage id="parking_lot_status" />
                    </th>
                    <th>
                      <FormattedMessage id="html" />
                    </th>
                  </thead>
                  <tbody>
                    {parkingLotStatusListDetails.map(
                      (data: any, index: number) => (
                        <tr key={index}>
                          <td>
                            {data.Category && (
                              <FormattedMessage id={data.Category} />
                            )}
                          </td>
                          <td>
                            {data.Status && (
                              <FormattedMessage id={data.Status} />
                            )}
                          </td>
                          <td>
                            <div className="mw-100 d-flex">
                              <button
                                onClick={() =>
                                  handleCopyClick(
                                    `${process.env.REACT_APP_BASE_URL}PL/${
                                      getTenantAccess.orgId
                                    }/${props.selectedData.PlId}/${
                                      categoryEnum[data.Category]
                                    }`
                                  )
                                }
                              >
                                <svg
                                  id="Layer_2"
                                  height="21"
                                  viewBox="0 0 24 24"
                                  width="21"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#0091ff"
                                >
                                  <path d="m5.4521 22h9.0957c1.7485 0 3.1822-1.3118 3.4064-3h.5936c1.9034 0 3.4522-1.5488 3.4522-3.4521v-10.0958c0-1.9033-1.5488-3.4521-3.4521-3.4521h-9.0958c-1.9033 0-3.4521 1.5488-3.4521 3.4521v.5479h-.5479c-1.9033 0-3.4521 1.5488-3.4521 3.4521v9.0957c0 1.9034 1.5488 3.4522 3.4521 3.4522zm2.5479-16.5479c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v10.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-.5479v-7.5479c0-1.9033-1.5488-3.4521-3.4521-3.4521h-6.5479zm-4 4c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v9.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-9.0958c-.8007 0-1.4521-.6514-1.4521-1.4521z" />
                                </svg>
                              </button>
                              <p>{`${process.env.REACT_APP_BASE_URL}PL/${
                                getTenantAccess.orgId
                              }/${props.selectedData.PlId}/${
                                categoryEnum[data.Category]
                              }`}</p>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <strong>
              <FormattedMessage id="current_schedule" />
            </strong>
          </div>
        </div>
        <div className="lg-12">
          <div className="tableDesign tableDevicesPanel mt-2">
            <div className="table-responsive" data-testid="table">
              <table className="table table-bordered">
                <thead>
                  <th>
                    <FormattedMessage id="schedule" />
                  </th>
                  <th>
                    <FormattedMessage id="data_1" />
                  </th>
                  <th>
                    <FormattedMessage id="data_2" />
                  </th>
                  <th>
                    <FormattedMessage id="data_3" />
                  </th>
                  <th>
                    <FormattedMessage id="data_4" />
                  </th>
                  <th>
                    <FormattedMessage id="data_5" />
                  </th>
                  <th>
                    <FormattedMessage id="data_6" />
                  </th>
                </thead>
                <tbody>
                  <tr className="text-center">
                    <td>
                      {currentScheduleDetails.ScheduleType.toString() == ""
                        ? ""
                        : scheduleTypeEnum[currentScheduleDetails.ScheduleType]}
                    </td>
                    <td>{currentScheduleDetails.Data1}</td>
                    <td>{currentScheduleDetails.Data2}</td>
                    <td>{currentScheduleDetails.Data3}</td>
                    <td>{currentScheduleDetails.Data4}</td>
                    <td>{currentScheduleDetails.Data5}</td>
                    <td>{currentScheduleDetails.Data6}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {getSignBoardImage?.IsSignBoardAvailable == true && (
          <div className="row mt-3">
            <div className="col-12">
              <strong>
                <FormattedMessage id="signboard_image" />
                <span>
                  <svg
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    className="ms-1 cursor-pointer"
                    height="20"
                    onClick={()=>{handleOpenSignBoardImage()}}
                    stroke-linejoin="round"
                    fill="currentColor"
                    stroke-miterlimit="2"
                    viewBox="0 0 24 24"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g transform="translate(-216.731 -90.903)">
                      <path d="m227.731 92.903h-5c-2.209 0-4 1.791-4 4v12c0 2.209 1.791 4 4 4h12c2.209 0 4-1.791 4-4 0-2.271 0-5 0-5 0-.552-.448-1-1-1s-1 .448-1 1v5c0 1.104-.896 2-2 2-3.33 0-8.671 0-12 0-1.105 0-2-.896-2-2 0-3.33 0-8.671 0-12 0-1.105.895-2 2-2h5c.552 0 1-.448 1-1s-.448-1-1-1zm7.586 2h-3.586c-.552 0-1-.448-1-1s.448-1 1-1h6c.552 0 1 .448 1 1v6c0 .552-.448 1-1 1s-1-.448-1-1v-3.586l-7.293 7.293c-.39.39-1.024.39-1.414 0-.391-.39-.391-1.024 0-1.414z" />
                    </g>
                  </svg>
                </span>
              </strong>
            </div>
            <div className="image_box mt-3">
              <img
                src={getSignBoardImage?.ImageSource}
                className="img-fluid img-thumbnail"
              />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default observer(ParkingLotDetails);
