/*    
<summary>
   This class component is all about Managing Authentication functionality.
   Developer:Aashish Singh, Created Date:29-March-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import base64 from "base-64";
import { action, computed, makeObservable, observable } from "mobx";
import * as baseService from "../service/base-service";
import { ICommonState } from "../../models/state/ICommonState";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import { IAuthState } from "../../models/state/IAuthState";
import IAuthResponse from "../../models/response/IAuthResponse";
import URLConstants from "../../constants/url-constants";
import ILogin from "../../models/forms/ILogin";
import { jwtDecode } from "jwt-decode";
import toast from "react-hot-toast";
import { formatMessage } from "../../translations/format-message";
import { IRoleVM } from "../../models/response/IRoleResponse";
import { PermissionType } from "../../constants/enums/permission-type-enum";
import userTypeEnum from "../../constants/enums/user-type-enum";

export class AuthStore implements IAuthState, ICommonState {
  inProgress = false;
  error = "";
  isAuthenticated = false;
  token = "";
  userId: number = 0;
  tenantId: number = 0;
  userType = "";
  email = "";
  loginFormData: ILogin = {
    email: "",
    password: "",
    rememberMe: false,
  };
  logoutSuccess = false;
  logoutError = "";
  logoutInprogress = false;

  permissions: Array<IRoleVM> = [];

  constructor() {
    makeObservable(this, {
      inProgress: observable,
      error: observable,
      tenantId: observable,
      userId: observable,
      isAuthenticated: observable,
      loginFormData: observable,
      logoutSuccess: observable,
      logoutError: observable,
      logoutInprogress: observable,
      permissions: observable,
      reset: action,
      login: action,
      logout: action,
      getUserId: computed,
      getPermissionType: computed ,
      getAllPermission: computed
    });
  }

  get getUserType() {
    if(this.userType){
      return this.userType;
    }
    return localStorage.getItem("hips_userType");
  }

  get getPermissionType() {
    return Number(localStorage.getItem("hips_permission_type"));
  }

  get getToken() {
    return localStorage.getItem("hips_token");
  }

  get getRefreshToken() {
    return localStorage.getItem("hips_refreshToken");
  }

  get getEmail() {
    return localStorage.getItem("hips_email");
  }

  get getUserId() {
    let userId = localStorage.getItem("hips_userId")
      ? localStorage.getItem("hips_userId")
      : "0";
    return parseInt(userId!);
  }

  get getAllPermission() {
    if(this.permissions) {
        return [...this.permissions];
    }
    return [];
}

  /*
    This function is used to reset all observables to their initial values.  
    */

  reset = () => {
    this.error = "";
    this.inProgress = false;
    this.isAuthenticated = false;
    this.logoutError = "";
    this.logoutInprogress = false;
    this.logoutSuccess = false;
    this.loginFormData = {
      email: "",
      password: "",
      rememberMe: false,
    };
  };
  /*
    This function is used to reset Logout observables to their initial values.  
    */

  resetLogout = () => {
    this.logoutError = "";
    this.logoutInprogress = false;
    this.logoutSuccess = false;
  };

  /*
    This function is used to Authenticate User and get token (if authentic) from API.  
    */

  login = (data: ILogin) => {
    this.inProgress = true;
    this.error = "";
    this.loginFormData = JSON.parse(JSON.stringify(data));
    const authData = {
      Email: data.email,
      Password: base64.encode(data.password),
    };
    return baseService
      .postRequest(URLConstants.Authenticate, authData)
      .then((response: IApiResponse<IApiSuccessResponse<IAuthResponse>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
        } else {
        let data = response.data.Data;
        let permissions:Array<IRoleVM> = data.Role ? data.Role: [];
          this.permissions = permissions;
          const userToken: any = jwtDecode(data.AccessToken);
          this.userType = userTypeEnum[userToken.userType];
          localStorage.setItem("hips_email", userToken.userEmail);
          localStorage.setItem("hips_userId", data.UserId);
          localStorage.setItem("hips_userType", userTypeEnum[userToken.userType]);
          localStorage.setItem("hips_token", data.AccessToken);
          localStorage.setItem("hips_permission_type",  PermissionType[userToken.permissionType]);
          localStorage.setItem("hips_is_access_tenant","false");
          localStorage.setItem("hips_access_tenant_id",userToken.tenantId);
          localStorage.setItem("hips_access_tenant_name","");
          localStorage.setItem('hips_permissions', JSON.stringify(permissions));
          this.isAuthenticated = true;
          toast.success(formatMessage("login_successfully"));
        }
      })
      .catch((err: string) => {
        this.error = err;
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  /*
    This function is used to reset all observables to their initial values and clear local storage.  
    */

  logout = () => {
    this.logoutInprogress = true;
    return baseService.postRequest(URLConstants.Logout, {})
        .then((response: IApiResponse<any>) => {
            if(response.data.Error)
            {
                this.logoutError = response.data.Message;
            }
            else{
                this.reset();
                const lang:any = localStorage.getItem("hips_language")
                localStorage.clear();
                localStorage.setItem("hips_language", lang === "jp" ? "jp" : "en");
                localStorage.setItem("hips_is_access_tenant","false");
                localStorage.setItem("hips_access_tenant_id","0");
                localStorage.setItem("hips_access_tenant_name","");
                this.logoutSuccess = true;
            }
        })
        .catch((err: string) => {
            this.logoutError = err;
        })
        .finally(action(() => {
            this.logoutInprogress = false;
        }));
  };
}

export default new AuthStore();
