/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import "./add-update-linkbase-in.css";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../contexts/store-provider";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import AddUpdateLinkbaseInForm from "../forms/add-update-linkbase-in-form";
import _ from "lodash";
interface IProps {
  id: number;
  parkingLotId: string;
  modalClosed: () => void;
}

const AddUpdateLinkbaseIn = (props: IProps) => {
  const { preferencesStore, linkbaseStore } = useStore();
  const {
    allAvailableSensorTypes,
    getLinkbaseIn,
    AddLinkBaseInService,
    resetAddLinkBaseInServiceState,
    UpdateLinkBaseInService,
    setLinkbaseInState,
    GetLinkbaseInByParkingLotId,
    getLinkbaseInByPlIdState,
    getLinkBaseInRelayByRelaTypeState,
    getLinkbaseInRelayByRelayType,
    GetLinkbaseInRelayByRelayTypeService,
    resetGetLinkbaseInRelayType,
    resetGetLinkbaseInRelayTypeState,
    DeleteLinkbaseInService,
    deleteLinkbaseInState,
    resetDeleteLinkbaseInState,
  } = linkbaseStore;
  const initailValues = getLinkbaseIn;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    GetLinkbaseInByParkingLotId(props.id, getTenantAccessFromLocalStorage());
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (setLinkbaseInState.success) {
      toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [setLinkbaseInState.success]);

  useEffect(() => {
    if (deleteLinkbaseInState.success) {
      toast.success(formatMessage("deleted_success"));
      resetDeleteLinkbaseInState();
      onModalClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLinkbaseInState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "userDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (setLinkbaseInState.error) {
      toast.error(formatMessage(setLinkbaseInState.error));
      resetAddLinkBaseInServiceState();
    }
  }, [setLinkbaseInState.error]);

  const getData = (id: number) => {
    GetLinkbaseInRelayByRelayTypeService(id);
  };

  /**
   * This function adds the branch when "Id < 0" and updates the Existing branch when Id > 0 by providing form values to the
   * correponding function
   */
  const onSubmitForm = (values: any) => {
    let isUpdateLinkBaseIn: boolean = false;
    let isLinkbaseArrayChanged: boolean = false;
    let isLinkbaseTypeChanged: boolean = false;
    let isLinkbaseSimNumberChanged: boolean = false;
    let LinkbaseInRelays: any[] = [];
    let modifyLinkBaseInRelays: any[] = [];
    debugger
    if (values.SimId > 0) {
      isUpdateLinkBaseIn = true;
    }

    for (var i = 0; i < values.LinkBaseInRelays?.length; i++) {
      if (
        values.LinkBaseInRelays[i].Id > 0 ||
        values.LinkBaseInRelays[i].SimId > 0
      ) {
        LinkbaseInRelays.push({
          RelayValue: values.LinkBaseInRelays[i].InputFormat,
          RelayStatus: Number(values.LinkBaseInRelays[i].Status),
          SensorType: values.LinkBaseInRelays[i].SensorType,
          Comment: values.LinkBaseInRelays[i].Comment,
        });
      }
    }

    for (
      var i = 0;
      i < values.oldInitialValues?.LinkBaseInRelays?.length;
      i++
    ) {
      modifyLinkBaseInRelays.push({
        RelayValue: values.oldInitialValues?.LinkBaseInRelays[i].InputFormat,
        RelayStatus: Number(
          values.oldInitialValues?.LinkBaseInRelays[i].Status
        ),
        SensorType: values.oldInitialValues?.LinkBaseInRelays[i].SensorType,
        Comment: values.oldInitialValues?.LinkBaseInRelays[i].Comment,
      });
    }

    if (!_.isEqual(LinkbaseInRelays, modifyLinkBaseInRelays)) {
      isLinkbaseArrayChanged = true;
    }

    if (
      values.LinkBaseInType?.toString() !==
      values?.oldInitialValues?.LinkBaseInType.toString()
    ) {
      isLinkbaseTypeChanged = true;
    }

    if (
      values.SimNumber?.toString().trim() !==
      values?.oldInitialValues?.SimNumber?.toString()?.trim()
    ) {
      isLinkbaseSimNumberChanged = true;
    }

    if (isUpdateLinkBaseIn != true) {
      AddLinkBaseInService({
        TenantId: getTenantAccessFromLocalStorage(),
        PlId: props.id,
        SimNumber: values.SimNumber?.trim(),
        LinkBaseInType: Number(values.LinkBaseInType),
        LinkBaseInDetails: LinkbaseInRelays,
      });
    } else {
      UpdateLinkBaseInService({
        TenantId: getTenantAccessFromLocalStorage(),
        PlId: props.id,
        SimNumber: values.SimNumber?.trim(),
        LinkBaseInType: Number(values.LinkBaseInType),
        IsSimNumberChanged: isLinkbaseSimNumberChanged ? true : false,
        IsLinkbaseChanged:
          isLinkbaseArrayChanged == true || isLinkbaseTypeChanged == true
            ? true
            : false,
        UpdateLinkBaseInDetails: LinkbaseInRelays,
      });
    }
  };

  const onDeleteLinkbaseIn = () => {
    DeleteLinkbaseInService(props.id, getTenantAccessFromLocalStorage());
  };

  /**
   * This function closes the pop up modal and reset getbrachDetails, addUpdate branch observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetDeleteLinkbaseInState();
    resetAddLinkBaseInServiceState();
    resetGetLinkbaseInRelayType();
    resetGetLinkbaseInRelayTypeState();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddUpdateLinkbaseInForm
        id={props.id}
        initialValues={initailValues}
        parkingLotId={props.parkingLotId}
        allLinkBaseInRelays={getLinkbaseInRelayByRelayType}
        sensorTypeOptions={allAvailableSensorTypes}
        getData={getData}
        deleteLinkbaseIn={onDeleteLinkbaseIn}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        isDataLoading={
          getLinkbaseInByPlIdState.inProgress ||
          getLinkBaseInRelayByRelaTypeState.inProgress
        }
        isLoading={
          setLinkbaseInState.inProgress ||
          getLinkBaseInRelayByRelaTypeState.inProgress
        }
      />
    </React.Fragment>
  );
};

export default observer(AddUpdateLinkbaseIn);
