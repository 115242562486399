import React, { useState, useEffect } from "react";
import { Form, Formik, Field, FieldArray, useFormikContext } from "formik";
import "./add-update-linkbase-out-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import { IOption } from "../../../models/ICommon";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { linkbaseParkingLotStatusOptions, parkingLotStatusOptions } from "../../../constants/options.constant";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import SignalLightEnum from "../../../constants/enums/signal-light-enum";
import {
  ILinkBaseOutRelayVM,
  LightObject,
} from "../../../models/response/ILinkbaseOutResponse";
import {
  AddLinkbaseOutDetailsValidationSchema,
  AddLinkbaseOutValidationSchema,
} from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";

interface IProps {
  id: number;
  initialValues: any;
  parkingLotId: string;
  soracomOptions: IOption[];
  slotTypeTarget: IOption[];
  cameraSlotTarget: IOption[];
  parkingLotTarget: IOption[];
  allLinkBaseOutRelays: any[];
  sensorTypeOptions: IOption[];
  targetTypeOptions: IOption[];
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  getData: (id: number) => void;
  isDataLoading: boolean;
  isLoading: boolean;
}

const AddUpdateLinkbaseOutForm = (props: IProps) => {
  const { initialValues, submitHandler, isLoading, onModalClosed } = props;
  const [data, setData] = useState({ ...initialValues });
  const [currentStep, setCurrentStep] = useState<number>(0);

  const [selectedType, setSelectedType] = useState<number>(-1);
  const FormObserver: React.FC = () => {
    const { values, setFieldValue, setFieldError } = useFormikContext<any>();

    useEffect(() => {
      if (values.RelayType > -1) {
        setSelectedType(Number(values.RelayType));
      }
      if (values.RelayType > -1 && props.allLinkBaseOutRelays?.length > 0) {
        const createdSensorArray = createSensorArray(
          props.allLinkBaseOutRelays,
          props.initialValues?.LinkBaseOutRelays
        );
        setFieldValue("LinkBaseOutRelays", createdSensorArray);
      }
    }, [values.RelayType]);

    // useEffect(() => {
    //   if (values.RelayType > -1 && props.allLinkBaseOutRelays?.length > 0) {
    //     debugger
    //     // setFieldValue("LinkBaseOutRelays[0].Target", -1);
    //     if(values?.LinkBaseOutRelays[0]?.TargetType > -1 && values?.LinkBaseOutRelays[0]?.Target == -1){
    //       setFieldError("LinkBaseOutRelays[0].Target", 'field_required');
    //     }
    //   }
    // }, [values?.LinkBaseOutRelays[0]]);

    return null;
  };

  useEffect(() => {
    if (selectedType > -1) {
      props.getData(Number(selectedType));
    }
  }, [selectedType]);

  const defaultObject: LightObject = {
    LinkBaseOutId: -1,
    LinkBaseOutSimId: 0,
    PlId: -1,
    RelayValue: "",
    RelayStatus: -1,
    LightType: 0,
    TargetType: -1,
    Target: -1,
    Comment: "",
    Disabled: true,
  };

  const createSensorArray = (
    inputArray: LightObject[],
    getDataArray: any[]
  ): any[] => {
    if (
      props.initialValues?.SimId > 0 &&
      selectedType == props.initialValues?.RelayType
    ) {
      const lightType0 = getDataArray?.filter((obj) => obj.LightType === 0);
      const lightType1 = getDataArray?.filter((obj) => obj.LightType === 1);
      const lightType2 = getDataArray?.filter((obj) => obj.LightType === 2);
      const lightType3 = getDataArray?.filter((obj) => obj.LightType === 3);
      return calculateObjs(lightType0, lightType1, lightType2, lightType3);
    } else {
      const lightType0 = inputArray?.filter((obj) => obj.LightType === 0);
      const lightType1 = inputArray?.filter((obj) => obj.LightType === 1);
      const lightType2 = inputArray?.filter((obj) => obj.LightType === 2);
      const lightType3 = inputArray?.filter((obj) => obj.LightType === 3);
      return calculateObjs(lightType0, lightType1, lightType2, lightType3);
    }
  };

  const calculateObjs = (
    light1: any[],
    light2: any[],
    light3: any[],
    light4: any[]
  ) => {
    // Calculate how many default objects are needed for each SensorType
    const defaultLightType1Count = Math.max(0, 4 - light1.length);
    const defaultLightType2Count = Math.max(0, 4 - light2.length);
    const defaultLightType3Count = Math.max(0, 2 - light3.length);
    const defaultLightType4Count = Math.max(0, 2 - light4.length);

    // Create default objects for Light Type 0...3
    const defaultLightType1 = Array.from(
      { length: defaultLightType1Count },
      () => ({
        ...defaultObject,
        LightType: 0,
      })
    );

    const defaultLightType2 = Array.from(
      { length: defaultLightType2Count },
      () => ({
        ...defaultObject,
        LightType: 1,
      })
    );

    const defaultLightType3 = Array.from(
      { length: defaultLightType3Count },
      () => ({
        ...defaultObject,
        LightType: 2,
      })
    );

    const defaultLightType4 = Array.from(
      { length: defaultLightType4Count },
      () => ({
        ...defaultObject,
        LightType: 3,
      })
    );

    // Combine all objects and limit the total to 8
    const combinedArray = [
      ...light1,
      ...defaultLightType1,
      ...light2,
      ...defaultLightType2,
      ...light3,
      ...defaultLightType3,
      ...light4,
      ...defaultLightType4,
    ];

    return combinedArray.slice(0, 12);
  };

  useEffect(() => {
    setData({ ...initialValues });
  }, [initialValues]);

  const handleNextStep = (newData: any, final: boolean = false) => {
    setData((prev: any) => ({ ...prev, ...newData }));
    if (final) {
      let tempValues: any = { ...newData };
      submitHandler(tempValues);
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData: any) => {
    setData((prev: any) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const StepOneTwo = (props1: any) => {
    const handleSubmit = (values: any) => {
      props1.next(values);
    };
    return (
      <Formik
        initialValues={props1.data}
        onSubmit={handleSubmit}
        validationSchema={AddLinkbaseOutValidationSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ values, isValid }) => (
          <Form id="formBody">
            {props.isDataLoading ||
              (isLoading && (
                <FormLoader loading={props.isDataLoading || isLoading} />
              ))}
            <div className="pt-3">
              <div className="progress step-progress">
                <div className="progress-bar" role="progressbar"></div>
              </div>
              <div className="step-container d-flex justify-content-between">
                <div className="step-circle active">
                  1{" "}
                  <span className="step-detail">
                    <FormattedMessage id="basic_details" />
                  </span>
                </div>
                <div className="step-circle">
                  2{" "}
                  <span className="step-detail">
                    <FormattedMessage id="relay_type_details" />
                  </span>
                </div>
              </div>
            </div>
            {/* } */}

            <div className="row">
              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style  col-form-label"
                    required={true}
                    label="relay_type"
                  />
                  <Field
                    data-testid="PlName"
                    className="form-control"
                    name="RelayType"
                    placeholder="relay_type"
                    options={props.sensorTypeOptions}
                    as={FormikFormSelect}
                  />
                </div>
              </div>

              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style col-form-label"
                    required={true}
                    label="sim_number"
                  />
                  <Field
                    data-testid="ParkingLotId"
                    className="form-control"
                    name="SimId"
                    placeholder="sim_number"
                    invalidcharacter={[" "]}
                    disabled={props.id > -1 ? true : false}
                    type="number"
                    as={FormikFormInput}
                  />
                </div>
              </div>

              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style  col-form-label"
                    required={true}
                    label="choose_soracom"
                  />
                  <Field
                    data-testid="PlName"
                    className="form-control"
                    name="SoracomId"
                    placeholder="choose_soracom"
                    options={props.soracomOptions}
                    as={FormikFormSelect}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style"
                    required={false}
                    label="comment"
                  />
                  <Field
                    rows={3}
                    id="Comment"
                    className="form-control"
                    name="Comment"
                    placeholder="comment"
                    type="text"
                    as={FormikFormTextArea}
                  />
                </div>
              </div>
            </div>

            <div className="pb-3 mt-4 d-flex justify-content-center">
              <LoaderButton
                type="submit"
                id="SaveParkingLot"
                isLoading={props.isLoading}
                text="next"
              />
              {/* {props.id > 0 && (
                <LoaderButton
                  type={isValid ? "submit" : "button"}
                  id="SaveTenant"
                  onClick={
                    isValid
                      ? () => {
                          props1.next(values, true);
                        }
                      : undefined
                  }
                  isLoading={props.isLoading}
                  text="update_parking"
                  className="ms-3"
                />
              )} */}
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const StepTwo = (props2: any) => {
    const handleSubmit = (values: any) => {
      props2.next({ ...values }, true); // Set final to true to submit the form
    };

    const returnIndex = (index:number) => {
      if(index <= 3) {
        return 0;
      }
      else if(index > 3 && index <= 7){
        return 4;
      }
      else if(index > 7 && index <= 9){
        return 8;
      }
      else{
        return 10;
      }
    }

    return (
      <Formik
        initialValues={props2.data}
        onSubmit={handleSubmit}
        validationSchema={AddLinkbaseOutDetailsValidationSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ values }) => {
          return (
            <Form id="formBody">
              {props.isDataLoading ||
                (isLoading && (
                  <FormLoader loading={props.isDataLoading || isLoading} />
                ))}

              <FormObserver />
              <div className="pt-3">
                <div className="progress px-1 step-progress">
                  <div
                    className={`progress-bar ${
                      props.id === -1 ? "w-100" : "w-100"
                    }`}
                    role="progressbar"
                  ></div>
                </div>
                <div className="step-container d-flex justify-content-between">
                  <div className="step-circle completed">
                    1{" "}
                    <span className="step-detail">
                      <FormattedMessage id="basic_details" />
                    </span>
                  </div>
                  <div className="step-circle active">
                    2{" "}
                    <span className="step-detail">
                      <FormattedMessage id="relay_type_details" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="tableDesign border">
                  <table className="table table-bordered border linkbaseout-table">
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage id="signal_light" />
                        </th>
                        <th>
                          <FormattedMessage id="target_type" />
                        </th>
                        <th>
                          <FormattedMessage id="target_data" />
                        </th>
                        <th>
                          <FormattedMessage id="relay" />
                        </th>
                        <th>
                          <FormattedMessage id="status" />
                        </th>
                        <th>
                          <FormattedMessage id="comment" />
                        </th>
                        {/* <th>
                          index
                        </th> */}
                      </tr>
                    </thead>
                    <FieldArray name="LinkBaseOutRelays">
                      {({ insert, remove, push }) => (
                        <tbody>
                          {values.LinkBaseOutRelays?.map(
                            (linkbase: ILinkBaseOutRelayVM, index: number) => (
                              <tr key={index} className="">
                                {linkbase?.LightType == 0 &&
                                  index % 4 === 0 && (
                                    <td
                                      className={"col-3 text-center light1"}
                                      rowSpan={4}
                                    >
                                      <FormattedMessage
                                        id={SignalLightEnum[linkbase.LightType]}
                                      />
                                    </td>
                                  )}

                                {linkbase?.LightType == 1 &&
                                  index % 4 === 0 && (
                                    <td
                                      className={"col-3 text-center light2"}
                                      rowSpan={4}
                                    >
                                      <FormattedMessage
                                        id={SignalLightEnum[linkbase.LightType]}
                                      />
                                    </td>
                                  )}

                                {linkbase?.LightType == 2 &&
                                  index % 2 === 0 && (
                                    <td
                                      className={"col-3 text-center light3"}
                                      rowSpan={2}
                                    >
                                      <FormattedMessage
                                        id={SignalLightEnum[linkbase.LightType]}
                                      />
                                    </td>
                                  )}

                                {linkbase?.LightType == 3 &&
                                  index % 2 === 0 && (
                                    <td
                                      className={"col-3 text-center light4"}
                                      rowSpan={2}
                                    >
                                      <FormattedMessage
                                        id={SignalLightEnum[linkbase.LightType]}
                                      />
                                    </td>
                                  )}

                                {(linkbase?.LightType == 0 ||
                                  linkbase?.LightType == 1) &&
                                  index % 4 === 0 && (
                                    <>
                                      <td className="link-out" rowSpan={4}>
                                        <Field
                                          data-testid="status"
                                          className="form-control"
                                          name={`LinkBaseOutRelays.${index}.TargetType`}
                                          placeholder="status"
                                          disabled={
                                            // props.id > 0 ||
                                            values.LinkBaseOutRelays[index]
                                              .Disabled
                                          }
                                          options={props.targetTypeOptions}
                                          as={FormikFormSelect}
                                        />
                                      </td>
                                      <td className="link-out" rowSpan={4}>
                                        <Field
                                          data-testid="Target"
                                          className="form-control"
                                          name={`LinkBaseOutRelays.${index}.Target`}
                                          placeholder="target"
                                          options={
                                            values?.LinkBaseOutRelays[index]
                                              .TargetType == "0"
                                              ? props.parkingLotTarget
                                              : values?.LinkBaseOutRelays[index]
                                                  .TargetType == "1"
                                              ? props.cameraSlotTarget
                                              : values?.LinkBaseOutRelays[index]
                                                  .TargetType == "2"
                                              ? props.slotTypeTarget
                                              : [
                                                  {
                                                    id: -1,
                                                    value: "please_select",
                                                    disabled: false,
                                                  },
                                                ]
                                          }
                                          disabled={
                                            values.LinkBaseOutRelays[index]
                                              .Disabled
                                          }
                                          as={FormikFormSelect}
                                        />
                                      </td>
                                    </>
                                  )}

                                {(linkbase?.LightType == 2 ||
                                  linkbase?.LightType == 3) &&
                                  index % 2 === 0 && (
                                    <>
                                      <td className="link-out" rowSpan={2}>
                                        <Field
                                          data-testid="status"
                                          className="form-control"
                                          name={`LinkBaseOutRelays.${index}.TargetType`}
                                          placeholder="status"
                                          disabled={
                                            // props.id > 0 ||
                                            values.LinkBaseOutRelays[index]
                                              .Disabled
                                          }
                                          options={props.targetTypeOptions}
                                          as={FormikFormSelect}
                                        />
                                      </td>
                                      <td className="link-out" rowSpan={2}>
                                        <Field
                                          data-testid="Target"
                                          className="form-control"
                                          name={`LinkBaseOutRelays.${index}.Target`}
                                          placeholder="target"
                                          options={
                                            values?.LinkBaseOutRelays[index]
                                              .TargetType == "0"
                                              ? props.parkingLotTarget
                                              : values?.LinkBaseOutRelays[index]
                                                  .TargetType == "1"
                                              ? props.cameraSlotTarget
                                              : values?.LinkBaseOutRelays[index]
                                                  .TargetType == "2"
                                              ? props.slotTypeTarget
                                              : [
                                                  {
                                                    id: -1,
                                                    value: "please_select",
                                                    disabled: false,
                                                  },
                                                ]
                                          }
                                          disabled={
                                            values.LinkBaseOutRelays[index]
                                              .Disabled
                                          }
                                          as={FormikFormSelect}
                                        />
                                      </td>
                                    </>
                                  )}

                                <Field name={`LinkBaseOutRelays.${index}`}>
                                  {({ field }: any) => (
                                    <React.Fragment>
                                      <td className="link-out">
                                        {linkbase?.RelayValue}
                                      </td>
                                      <td className="link-out">
                                        <Field
                                          data-testid="status"
                                          className="form-control"
                                          name={`LinkBaseOutRelays.${index}.RelayStatus`}
                                          placeholder="status"
                                          disabled={
                                            values.LinkBaseOutRelays[index]
                                              .Disabled
                                          }
                                          options={   
                                            values?.LinkBaseOutRelays[returnIndex(index)]
                                              .TargetType == "0"
                                              ? parkingLotStatusOptions
                                              : values?.LinkBaseOutRelays[returnIndex(index)]
                                                  .TargetType == "1"
                                              ? linkbaseParkingLotStatusOptions
                                              : values?.LinkBaseOutRelays[returnIndex(index)]
                                                  .TargetType == "2"
                                              ? parkingLotStatusOptions
                                              : [
                                                  {
                                                    id: -1,
                                                    value: "please_select",
                                                    disabled: false,
                                                  },
                                                ]
                                          }
                                          as={FormikFormSelect}
                                        />
                                      </td>
                                      <td className="link-out">
                                        <Field
                                          data-testid="Comment"
                                          className="form-control"
                                          name={`LinkBaseOutRelays.${index}.Comment`}
                                          placeholder="comment"
                                          disabled={
                                            values.LinkBaseOutRelays[index]
                                              .Disabled
                                          }
                                          type="text"
                                          as={FormikFormInput}
                                        />
                                      </td>
                                    </React.Fragment>
                                  )}
                                </Field>
                              </tr>
                            )
                          )}
                        </tbody>
                      )}
                    </FieldArray>
                  </table>
                </div>
              </div>
              <div className="pb-3 mt-4 d-flex justify-content-center">
                <Button
                  data-testid="back"
                  variant="light-secondary"
                  onClick={() => {
                    props2.prev(values);
                  }}
                  disabled={props.isLoading}
                  className="mr-3"
                >
                  <FormattedMessage id="back" />
                </Button>
                <LoaderButton
                  type="submit"
                  id="SaveParkingLot"
                  className="ms-2"
                  isLoading={props.isLoading}
                  text={props.id == -1 ? "submit" : "update_parking"}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const steps = [
    <StepOneTwo
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      Id={props.id}
      isLoading={isLoading}
    />,
    <StepTwo
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      Id={props.id}
      isLoading={isLoading}
    />,
  ];

  return (
    <div>
      {isLoading && <FormLoader loading={isLoading} />}
      {steps[currentStep]}
    </div>
  );
};

export default AddUpdateLinkbaseOutForm;
