/**
 * This functional Component "AddMedia" define props like inital values and functions for child form component.
 * Created Date: 23-April-2024
 */

import "./add-edit-media.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStore } from "../../../../contexts/store-provider";
import AddUpdateImageMediaForm from "../../forms/add-edit-media/edit-image-media-form";
import AddUpdateVideoMediaForm from "../../forms/add-edit-media/edit-video-media-form";
import { initialState as AddMediaState } from "../../../../core/initialState/add-media-state";
import toast from "react-hot-toast";
import { formatMessage } from "../../../../translations/format-message";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { MediaType } from "../../../../constants/enums/media-type-enums";
import { getTenantAccessFromLocalStorage } from "../../../../helpers/localstorage-helper";
import AddMediaForm from "../../forms/add-edit-media/add-media-form";

interface IProps {
  id: number;
  mediaType: number;
  selectedFolderType: number;
  modalClosed: () => void;
}
const AddEditMedia = (props: IProps) => {
  const { mediaStore, preferencesStore } = useStore();
  const {
    AddImageMediaService,
    AddVideoMediaService,
    UpdateImageMediaService,
    UpdateVideoMediaService,
    addUpdateMediaState,
    GetMediaImageByIdService,
    GetMediaVideoByIdService,
    mediaState,
    imageMediaDetail,
    videoMediaDetail,
    resetAddUpdateMediaState,
    resetImageMediaDetail,
    resetVideoMediaDetail,
    GetAllFolders,
    getAllFolderState,
    allAvailableFolders,
  } = mediaStore;
  const { getTenantAccess, getMediaAccess } = preferencesStore;
  const imageTypeFormInitialValues: any = imageMediaDetail;
  const videoTypeFormInitialValues: any = videoMediaDetail;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    GetAllFolders(getTenantAccessFromLocalStorage());
    if (props.id > 0) {
      if (props.mediaType == 0) {
        GetMediaImageByIdService(props.id, getTenantAccessFromLocalStorage());
      } else {
        GetMediaVideoByIdService(props.id, getTenantAccessFromLocalStorage());
      }
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateMediaState.success) {
      if (props.id === -1) toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdateMediaState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateMediaError" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateMediaState.error) {
      resetAddUpdateMediaState();
    }
  }, [addUpdateMediaState.error]);

  /**
   * This function closes the pop up modal and reset getmediadetails, addUpdate media observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    if (props.mediaType == 0) {
      resetImageMediaDetail();
    } else {
      resetVideoMediaDetail();
    }
    props.modalClosed();
  };

  /**
   * This function add media when "Id < 0" and updates existing Image / Video when Id > 0 by providing form values to corresponding function
   */
  const onSubmitForm = (values: any) => {
    if (props.id < 0) {
      if ((values.Extension == "jpg") || (values.Extension == "jpeg") || (values.Extension == "png") || (values.Extension == "svg")) {
        let obj = {
          TenantId: getTenantAccessFromLocalStorage(),
          FolderId: getMediaAccess.folderId,
          MediaType: MediaType.Image,
          ImageName: values.Extension
            ? values.MediaName + "." + values.Extension
            : values.MediaName,
          FolderCategoryType: props.selectedFolderType,
          PricingValue:
            values.PricingValue != ""
              ? Number(values.PricingValue)
              : values.PricingValue,
          File: values.File,
          Height: (props.selectedFolderType == 3 || props.selectedFolderType == 4 || props.selectedFolderType == 5) ? Number(values.Height) : null,
          Width: (props.selectedFolderType == 3 || props.selectedFolderType == 4 || props.selectedFolderType == 5) ? Number(values.Width) : null,  
          IsGenerateHtml: true,
          Base64: values.Base64,
          Comment: values.Comment,
        };
        AddImageMediaService(obj);
      } else if(values.Extension == "mp4") {
        let obj = {
          TenantId: getTenantAccessFromLocalStorage(),
          FolderId: getMediaAccess.folderId,
          MediaType: MediaType.Video,
          VideoName: values.Extension
            ? values.MediaName + "." + values.Extension
            : values.MediaName,
          File: values.File,
          IsGenerateHtml: true,
          Height: (props.selectedFolderType == 3 || props.selectedFolderType == 4 || props.selectedFolderType == 5) ? Number(values.Height) : null,
          Width: (props.selectedFolderType == 3 || props.selectedFolderType == 4 || props.selectedFolderType == 5) ? Number(values.Width) : null,  
          Comment: values.Comment,
        };
        AddVideoMediaService(obj);
      }
    } else {
      if (props.mediaType == 0) {
        let obj = {
          Id: props.id,
          TenantId: getTenantAccessFromLocalStorage(),
          FolderId: getMediaAccess.folderId,
          PricingValue:
            values.PricingValue != ""
              ? Number(values.PricingValue)
              : values.PricingValue,
          IsGenerateHtml: true,
          Height: (props.selectedFolderType == 3 || props.selectedFolderType == 4 || props.selectedFolderType == 5) ? Number(values.Height) : null,
          Width: (props.selectedFolderType == 3 || props.selectedFolderType == 4 || props.selectedFolderType == 5) ? Number(values.Width) : null,  
          Comment: values.Comment,
        };
        UpdateImageMediaService(props.id, obj);
      } else {
        let obj = {
          Id: props.id,
          TenantId: getTenantAccessFromLocalStorage(),
          FolderId: getMediaAccess.folderId,
          IsGenerateHtml: true,
          Height: (props.selectedFolderType == 3 || props.selectedFolderType == 4 || props.selectedFolderType == 5) ? Number(values.Height) : null,
          Width: (props.selectedFolderType == 3 || props.selectedFolderType == 4 || props.selectedFolderType == 5) ? Number(values.Width) : null,  
          Comment: values.Comment,
        };
        UpdateVideoMediaService(obj);
      }
    }
  };

  return (
    <React.Fragment>
      <Modal
        className="correction-modal fancy-modal add-device-modal"
        size="lg"
        show={true}
        centered={true}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.id > 0 ?
            <>
              {props.mediaType == 0 ? (
                props.id < 0 ? (
                  <FormattedMessage id="add_image" />
                ) : (
                  <FormattedMessage id="update_image" />
                )
              ) : props.id < 0 ? (
                <FormattedMessage id="add_video" />
              ) : (
                <FormattedMessage id="update_video" />
              )}
            </>
            :
            <>
            <FormattedMessage id="add_media" />
            </>
            }
            
          </Modal.Title>
          <button
            onClick={onModalClosed}
            data-testid="Close"
            className="Crossicons"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="modal-body">
          {props.id > 0 ? (
            <>
              {props.mediaType == 0 ? (
                <AddUpdateImageMediaForm
                  initialValues={
                    props.mediaType == 0
                      ? imageTypeFormInitialValues
                      : videoTypeFormInitialValues
                  }
                  allFolders={allAvailableFolders}
                  id={props.id}
                  submitHandler={onSubmitForm}
                  onModalClosed={onModalClosed}
                  selectedFolderType={props.selectedFolderType}
                  isLoading={
                    mediaState.inProgress ||
                    addUpdateMediaState.inProgress ||
                    getAllFolderState.inProgress
                  }
                />
              ) : (
                <AddUpdateVideoMediaForm
                  initialValues={
                    props.mediaType == 0
                      ? imageTypeFormInitialValues
                      : videoTypeFormInitialValues
                  }
                  id={props.id}
                  selectedFolderType={props.selectedFolderType}
                  submitHandler={onSubmitForm}
                  onModalClosed={onModalClosed}
                  isLoading={
                    mediaState.inProgress ||
                    addUpdateMediaState.inProgress ||
                    getAllFolderState.inProgress
                  }
                />
              )}
            </>
          ) : (
            <>
              <AddMediaForm
                initialValues={AddMediaState}
                id={props.id}
                allFolders={allAvailableFolders}
                submitHandler={onSubmitForm}
                onModalClosed={onModalClosed}
                selectedFolderType={props.selectedFolderType}
                isLoading={
                  mediaState.inProgress ||
                  addUpdateMediaState.inProgress ||
                  getAllFolderState.inProgress
                }
              />
            </>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default observer(AddEditMedia);
